import React, { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { MenuItem, Select, Typography, useMediaQuery } from '@mui/material';
import { getAccessToken } from 'app/utils/common-utils/common-utils';
import AmyIcon from 'app/components/common/cards/chat-card/amy-icons';
import SideNavigation from 'app/components/layout/side-navigaion/side-navigation';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import PrimaryHeader from 'app/components/layout/header/primary-header';
import RecurringRangeModal from './recurring-date-range/recurring-range-modal';
import ActivityCardDetails from '../activity-details-section/activity-details-card/activity-details-card';
import {
  deleteActivityById,
  deleteActivityByIds,
  fetchActivities,
  fetchActivityDetails,
  fetchActivityDetailsUnauthorized,
  fetchRecurringDetails,
  fetchRecurringDetailsFilter,
  fetchunAuthorosiedActivities,
  fetchUnAuthRecurringDetails,
} from 'app/shared/reducers/activity-home';
import ActivityRecurringDetails from './recurring-details-card/recurring-details-card';
import RecurringDetailsSidePanel from './recurring-details-side-panel/recurring-details-side-pannel';
import toast from 'react-hot-toast';
import { updateVolunteeringEventId } from 'app/shared/reducers/activity-filter';
import { useItemId } from 'app/utils/hooks/useItemId';

const drawerWidth = 315;
const drawerWidthSm = 248;
interface Props {
  window?: () => Window;
}

export const RecurringSection = (props: Props) => {
  let token;
  const { window } = props;
  const activityId = useItemId();
  const [activityRespectiveId, setactivityRespectiveId] = useState<any>(0);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const [category, setCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const [activity, setActivity] = useState({});
  const [activityNotFound, setActivityNotFound] = useState(false);
  const [loading, setLoading] = useState(true);
  const [bookmarkSuccess, setBookmarkSuccess] = React.useState(false);
  const [toggleMapValue, setToggleMapValue] = useState(false);
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const eventType = useAppSelector(state => state.homeActivity.eventType);
  const leaveActivityType = useAppSelector(state => state.leaveActivityReducer.success);
  const reportActivity = useAppSelector(state => state.reportActivityReducer.success);
  const [volunteerButtonClick, setvolunteerButtonClick] = useState(false);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [likeSuccess, setLikemarkSuccess] = React.useState(false);
  const [blockedParticipant, setblockedParticipant] = React.useState(false);
  const makeModeratorSuccess = useAppSelector(state => state.activitParticipants.success);

  const isSmallScreen = useMediaQuery('(max-width:767px)');
  const breakPoint = useMediaQuery('(max-width:988px)');
  const handleButtonClicked = () => {};
  const handleButtonMapClicked = () => {};
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isDarkMode = theme.palette.mode === 'dark';
  const [childrens, setChildrens] = React.useState<any>([]);
  const [newChildrens, setNewChildrens] = React.useState<any>([]);

  const smallScreenStyles = {
    border: 'none',
  };

  const largeScreenStyles = {
    height: '85vh',
    overflowY: 'scroll',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '&::-webkit-scrollbar-thumb': { backgroundColor: 'secondary.contrastText', height: '10px' },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
  };

  const styles = {
    ...(isSmallScreen || breakPoint ? smallScreenStyles : largeScreenStyles),
  };

  const authTokenFromStorage = (async () => {
    try {
      const authToken = await getAccessToken();
      if (authToken) {
        return authToken;
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error:', error);
      return null;
    }
  })();
  (async () => {
    token = await authTokenFromStorage;
  })();

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleSubCategoryClick = (category: string, subCategory: string) => {
    setCategory(category);
    setSubCategory(subCategory);
  };

  const handleAccordionClick = (categoryId: any, currentState: any) => {
    setCategory(categoryId);
  };

  const handleBookmarkSuccess = () => {
    setBookmarkSuccess(s => !s);
  };
  const handleLikeSuccess = () => {
    setLikemarkSuccess(s => !s);
  };
  const handleVolunteerButtonClick = value => {
    setvolunteerButtonClick(value);
  };

  const handleIsSubmitClicked = value => {
    setIsSubmitClicked(value);
  };

  const handleParticipantBlocked = value => {
    setblockedParticipant(value);
  };
  const handleButtonFeatureClicked = () => {};
  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  }));
  const drawer = (
    <Box
      sx={{
        border: 'none',
        height: '100vh',
        overflowY: 'scroll',
        overflowX: 'hidden',
        background:
          theme.palette.mode === 'dark'
            ? theme.palette.background.default
            : 'transparent linear-gradient(133deg, #F7F5EF 35%, #F2F2FA 58%, #EFEFF5 100%) 0% 0% no-repeat padding-box',

        maxWidth: { sm: drawerWidthSm, lg: drawerWidth, md: drawerWidthSm },
        backdropFilter: 'blur(10px)',

        '&::-webkit-scrollbar': {
          display: 'none',
        },
        '&::-webkit-scrollbar-thumb': { backgroundColor: 'secondary.contrastText', height: '10px' },
        '&::-webkit-scrollbar-track': {
          backgroundColor: 'transparent',
        },
      }}
    >
      <DrawerHeader sx={{ justifyContent: 'center', height: '79px', border: 'none' }}>
        <Link to={'/'} style={{ paddingLeft: '0px', paddingRight: '50px' }}>
          <AmyIcon sx={{ width: '159px', height: '100px' }} />
        </Link>
      </DrawerHeader>

      <SideNavigation
        category={category}
        subCategory={subCategory}
        onSubCategoryClick={handleSubCategoryClick}
        onhandleAccordionClick={handleAccordionClick}
        onButtonClicked={handleButtonClicked}
        onMapClicked={handleButtonMapClicked}
        toggleMapValue={toggleMapValue}
        setToggleMapValue={setToggleMapValue}
        onFeatureClicked={handleButtonFeatureClicked}
      />
      <Divider />
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  useEffect(() => {
    dispatch(updateVolunteeringEventId(activityId));
  }, [activityId]);
  const [dataFromChild, setDataFromChild] = useState(null);
  const handleDataChange = newData => {
    setDataFromChild(newData);
  };
  useEffect(() => {
    const fetchData = async () => {
      if (activityId !== undefined) {
        try {
          if (isAuthenticated) {
            const response = await dispatch(fetchRecurringDetails(activityId));
            // Access the response data here()
            setChildrens(response?.payload);
          } else {
            const response = await dispatch(fetchUnAuthRecurringDetails(activityId));
            // Access the response data here()
            setChildrens(response?.payload);
          }
        } catch (error) {
          console.error('Recurring failed:', error);
        }
      }
    };

    fetchData();
  }, [activityId, dataFromChild]);

  useEffect(() => {
    const fetchData = async () => {
      if (activityId !== undefined) {
        try {
          if (isAuthenticated) {
            const response = await dispatch(fetchRecurringDetailsFilter({ id: activityId, filterType: 'VOLUNTEER' }));
            if (response) {
              const responsePayload = response?.payload as Array<{
                id: number;
                startDate: string;
                startTime: string;
                endTime: string;
                activityStatus: string;
                parentActivityStatus: string;
                disable: null | boolean;
                parent: boolean;
                participant: boolean;
                organizer: boolean;
              }>;
              const sortedResponse = responsePayload?.sort((a, b) => {
                if (a.parent && !b.parent) return -1;
                if (!a.parent && b.parent) return 1;
                return 0;
              });
              setNewChildrens(sortedResponse);
            }
          } else {
            const response = await dispatch(fetchUnAuthRecurringDetails(activityId));
            setNewChildrens(response?.payload);
          }
        } catch (error) {
          console.error('Recurring failed:', error);
        }
      }
    };

    fetchData();
  }, [activityId, dataFromChild]);

  useEffect(() => {
    if (childrens) {
      const filteredData = childrens.filter(item => !item.parent);
      const sortedData = filteredData.sort((a, b) => {
        if (a.participant !== b.participant) {
          return b.participant - a.participant;
        }
        if (a.participant) {
          const dateA = new Date(a.startDate).getTime();
          const dateB = new Date(b.startDate).getTime();
          return dateA - dateB;
        }
        return 0;
      });

      if (sortedData.length > 0) {
        const currentDate = new Date().getTime();

        if (dataFromChild && dataFromChild.length > 0) {
          const selectedItem = sortedData.find(item => dataFromChild.includes(item.id));
          if (selectedItem && !dataFromChild) {
            setactivityRespectiveId(selectedItem.id);
            return;
          }
        }

        const futureDates = sortedData.filter(item => new Date(item.startDate).getTime() >= currentDate);
        const pastDates = sortedData.filter(item => new Date(item.startDate).getTime() < currentDate);

        if (futureDates.length > 0 && !dataFromChild) {
          setactivityRespectiveId(futureDates[0]?.id);
        } else if (pastDates.length > 0 && !dataFromChild) {
          setactivityRespectiveId(pastDates[pastDates.length - 1]?.id);
        }
      }
    }
  }, [childrens, dataFromChild]);

  useEffect(() => {
    if (activityRespectiveId) {
      let response;
      const fetchActivitiesInfo = async () => {
        setLoading(true);
        if (isAuthenticated) {
          response = await dispatch(fetchActivityDetails(activityRespectiveId));
        } else {
          response = await dispatch(fetchActivityDetailsUnauthorized(activityRespectiveId));
        }
        if (response.meta.requestStatus == 'fulfilled') {
          setActivity(response.payload);
          setIsSubmitClicked(false);
          setActivityNotFound(false);
        } else {
          setActivityNotFound(true);
          toast.error('No activity details found.', { id: 'activity-fetch-error' });
        }
        setLoading(false);
      };
      fetchActivitiesInfo();
    }
    return () => {
      toast.dismiss('activity-fetch-error');
    };
  }, [
    isAuthenticated,
    volunteerButtonClick,
    isSubmitClicked,
    bookmarkSuccess,
    likeSuccess,
    leaveActivityType,
    reportActivity,
    makeModeratorSuccess,
    blockedParticipant,
    activityRespectiveId,
  ]);
  const handleDeleteClick = async (id: string) => {
    try {
      const response = await dispatch(deleteActivityByIds({ activityIds: [id], organizationId: 0, message: '' }));
      if (typeof response.payload === 'string') {
        try {
          if (authTokenFromStorage) {
            dispatch(fetchActivities({ eventType: eventType }));
            navigate('/');
          } else {
            dispatch(fetchunAuthorosiedActivities({ eventType: eventType }));
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDataUpdate = dataFromChild => {
    setactivityRespectiveId(dataFromChild);
  };
  return (
    <Box
      sx={{
        display: 'flex',
        background: isDarkMode ? '#1F1F26' : 'transparent linear-gradient(118deg, #F7F5EF 0%, #F2F2FA 49%, #EFEFF5 100%) 0% 0% no-repeat',
        p: 0,
        border: 'none',
        backdropFilter: 'blur(10px)',
        height: '100vh',
        overflow: 'hidden',
      }}
    >
      {/* topnav */}
      <CssBaseline />
      <AppBar
        elevation={0}
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidthSm}px)`, md: `calc(100% - ${drawerWidthSm}px)`, lg: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidthSm}px`, md: `${drawerWidthSm}px`, lg: `${drawerWidth}px` },
          background: isDarkMode ? '#2C2C38' : 'transparent',
        }}
      >
        <Toolbar
          className="amy-top-nav"
          sx={{
            backgroundColor: ' transparent linear-gradient(118deg, #F7F5EF 0%, #F2F2FA 49%, #EFEFF5 100%) 0% 0% no-repeat',
            background: isDarkMode ? '#1F1F26' : '',
            border: 'none',
            backdropFilter: 'blur(10px)',
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' }, top: '10px' }}
          >
            <MenuIcon />
          </IconButton>
          <PrimaryHeader />
        </Toolbar>
      </AppBar>
      {/* Topnav-end */}
      {/* SideNav */}
      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidthSm, md: drawerWidthSm, lg: drawerWidth },
          flexShrink: { sm: 0 },
          minHeight: '100vh',
          border: 'none',
          background: isDarkMode ? '#1F1F26' : 'transparent linear-gradient(118deg, #F7F5EF 0%, #F2F2FA 49%, #EFEFF5 100%) 0% 0% no-repeat',
        }}
        aria-label="activity-detail"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidthSm },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: { sm: drawerWidthSm, md: drawerWidthSm, lg: drawerWidth } },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      {/* SideNav-end */}
      {/* Main section */}
      <Box
        className="inside-bg"
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: '-webkit-fill-available',
        }}
      >
        <div className="row" style={{ padding: 1, marginTop: '50px' }}>
          <ArrowCircleLeftIcon
            onClick={() => handleGoBack()}
            sx={{ cursor: 'pointer', color: '#FFCE00', borderRadius: '50%', fontSize: '50px', marginLeft: '10px' }}
          />
        </div>

        <Box
          className="p-3"
          sx={{
            background: isDarkMode ? '#1F1F26' : '',
            height: '77vh',
            overflowY: 'scroll',
            overflowX: 'hidden',

            // overflow: 'auto',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          <div className="row screen-space">
            <div className={`col-md-${breakPoint ? '12' : '8'}`} style={{ paddingLeft: '0px !important' }}>
              <Box sx={styles}>
                <ActivityRecurringDetails
                  activityResponse={activity}
                  childrensResponse={childrens}
                  onBookmarkSuccess={handleBookmarkSuccess}
                  onLikeSuccess={handleLikeSuccess}
                  handleDeleteClick={handleDeleteClick}
                  onParticipantBlocked={handleParticipantBlocked}
                  onDataUpdate={handleDataUpdate}
                  onDataChange={handleDataChange}
                />
              </Box>
            </div>
            <div className={`col-md-${breakPoint ? '12' : '4'}`}>
              <div
                style={{
                  background: isDarkMode ? '#2C2C38' : '#FFFFFF',
                  borderRadius: '15px',
                  marginTop: isSmallScreen || breakPoint ? '2%' : '0',
                }}
              >
                <RecurringDetailsSidePanel
                  activityResponse={activity}
                  volunteerButtonClick={volunteerButtonClick}
                  isSubmitClicked={isSubmitClicked}
                  onVolunteerButtonClick={handleVolunteerButtonClick}
                  onIsSubmitClicked={handleIsSubmitClicked}
                  childrensResponse={newChildrens}
                  onDataChange={handleDataChange}
                />
              </div>
            </div>
          </div>
        </Box>
      </Box>
      {/* main-end */}
    </Box>
  );
};
export default RecurringSection;
