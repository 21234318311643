import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import dayjs from 'dayjs';
import { Typography, useTheme } from '@mui/material';
import './activity-end-time.scss';

type ActivityEndTimeProps = { formik: any };

const ActivityEndTime = ({ formik }: ActivityEndTimeProps) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const futureTime = new Date(new Date().getTime() + 15 * 60000);
  const initialDate = dayjs().add(15, 'minute').toDate();
  const [startDate, setStartDate] = useState<Date | null>(null);

  useEffect(() => {
    if (formik.values.activityEndTime != null && formik.values.activityEndTime != '') {
      setStartDate(new Date(formik.values.activityEndTime));
    }
  }, [formik.values.activityEndTime]);

  useEffect(() => {
    if (formik.values.activityEndTime === formik.values.activityStartTime) {
      console.log('check mine');
      const futureTime = new Date(new Date().getTime() + 15 * 60000);
      setStartDate(futureTime);
      formik.setFieldValue('activityEndTime', futureTime);
    }
  }, [formik.values.activityStartTime, formik.values.activityEndTime]);

  // Check if the start date from formik.values.startDate is today
  const isStartDateToday = formik.values.startDate ? dayjs().isSame(dayjs(formik.values.startDate), 'day') : false;

  // Adjust minTime and maxTime based on whether the start date is today
  const getTimeLimits = () => {
    const now = dayjs();
    let minTime = new Date();
    let maxTime = new Date();

    if (isStartDateToday) {
      minTime.setHours(now.hour(), Math.ceil(now.minute() / 15) * 15, 0, 0); // Round up to the nearest 15 minutes
      maxTime.setHours(23, 45, 0, 0); // Set last selectable time for today
    } else {
      minTime.setHours(0, 0, 0, 0); // Allow any time for future dates
      maxTime.setHours(23, 45, 0, 0);
    }

    return { minTime, maxTime };
  };

  const { minTime, maxTime } = getTimeLimits();

  const settingValue1 = date => {
    const selectedTime = dayjs(date);
    const startDateDatePart = dayjs(formik.values.startDate).format('YYYY-MM-DD');
    const newActivityEndTime = dayjs(`${startDateDatePart}T${selectedTime.format('HH:mm:ss')}`).toDate();

    setStartDate(newActivityEndTime);
    formik.setFieldValue('activityEndTime', newActivityEndTime);
  };

  return (
    <div style={{ height: '60px' }}>
      <style>
        {`.react-datepicker__time-container .react-datepicker__time{
          background-color: ${isDarkMode ? '#1F1F26 !important' : '#fff !important'};
          color: ${isDarkMode ? '#fff !important' : '#1F1F26 !important'}
        }
        `}
        {`.react-datepicker__header:not(.react-datepicker__header--has-time-select){
          background-color: ${isDarkMode ? '#1F1F26 !important' : '#fff !important'};
           border-bottom: none !important;        
        }
        `}
      </style>
      <DatePicker
        className={isDarkMode ? 'rom-timepickerdark ' : 'rom-timepicker '}
        selected={startDate}
        onChange={(date: Date | null) => {
          if (date) {
            console.log('yup enter');
            const selectedTime = dayjs(date);
            const startDateDatePart = dayjs(formik.values.startDate).format('YYYY-MM-DD');
            const newActivityEndTime = dayjs(`${startDateDatePart}T${selectedTime.format('HH:mm:ss')}`).toDate();

            setStartDate(newActivityEndTime);
            formik.setFieldValue('activityEndTime', newActivityEndTime);
            settingValue1(date);
          } else {
            setStartDate(null);
            formik.setFieldValue('activityEndTime', null);
          }
        }}
        onBlur={() => {
          formik.setFieldTouched('activityEndTime');
          formik.validateField('activityEndTime');
        }}
        onFocus={() => {
          formik.setFieldTouched('activityEndTime');
          formik.validateField('activityEndTime');
        }}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        dateFormat="h:mm aa"
        isClearable={false}
        placeholderText="End time*"
        onKeyDown={e => e.preventDefault()}
        minTime={minTime}
        maxTime={maxTime}
      />
      {formik.touched.activityEndTime && formik.errors.activityEndTime && Boolean(formik.errors.activityEndTime) && (
        <Typography variant="caption" sx={{ color: 'error.main' }}>
          {formik.touched.activityEndTime && formik.errors.activityEndTime ? 'Activity end time should be greater than start time ' : ''}
        </Typography>
      )}
    </div>
  );
};

export default ActivityEndTime;
