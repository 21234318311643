import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate, useParams, useSearchParams, useLocation } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import SideNavigation from 'app/components/layout/side-navigaion/side-navigation';
import ActivityCardDetails from 'app/components/ui/activity-details-section/activity-details-card/activity-details-card';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import {
  fetchActivities,
  fetchunAuthorosiedActivities,
  fetchActivityDetails,
  fetchActivityDetailsUnauthorized,
  deleteActivityByIds,
} from 'app/shared/reducers/activity-home';
import ActivityDetailsSidePanel from '../activity-details-side-panel/activity-details-side-panel';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import { getAccessToken } from 'app/utils/common-utils/common-utils';
import { MenuItem, Select, Typography, useMediaQuery } from '@mui/material';
import toast from 'react-hot-toast';
import PrimaryHeader from 'app/components/layout/header/primary-header';
import AmyIcon from 'app/components/common/cards/chat-card/amy-icons';
import PaymentResponseModal from 'app/components/common/modals/payment-reponse/payment-response';
import { useItemId } from 'app/utils/hooks/useItemId';

const drawerWidth = 315;
const drawerWidthSm = 248;
interface Props {
  window?: () => Window;
}
export const ActivitySection = () => {
  let token;
  const { id } = useParams();
  const location = useLocation(); // To get the current URL
  const activityId = useItemId();
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  console.log('activityId', activityId);
  useEffect(() => {
    return () => {
      localStorage.removeItem('dynamicDescription');
      localStorage.removeItem('dynamicTitle');
      localStorage.removeItem('dynamicImage');
      localStorage.removeItem('dynamicUrl');
    };
  }, []);

  const [category, setCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const [activity, setActivity] = useState<any>({});
  const [activityNotFound, setActivityNotFound] = useState(false);
  const [loading, setLoading] = useState(true);
  const [bookmarkSuccess, setBookmarkSuccess] = useState(false);
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const eventType = useAppSelector(state => state.homeActivity.eventType);
  const leaveActivityType = useAppSelector(state => state.leaveActivityReducer.success);
  const reportActivity = useAppSelector(state => state.reportActivityReducer.success);
  const [volunteerButtonClick, setvolunteerButtonClick] = useState(false);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [likeSuccess, setLikemarkSuccess] = useState(false);
  const [blockedParticipant, setblockedParticipant] = useState(false);
  const [showResponseModal, setShowResponseModal] = useState(false);
  const [isResponseSuccess, setIsResponseSuccess] = useState(false);
  const [toggleValue, setToggleValue] = useState(false);
  const [toggleMapValue, setToggleMapValue] = useState(false);
  const [toggleFeatureValue, setToggleFeatureValue] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState<string>('');

  const makeModeratorSuccess = useAppSelector(state => state.activitParticipants.success);
  const isSmallScreen = useMediaQuery('(max-width:767px)');
  const breakPoint = useMediaQuery('(max-width:988px)');
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isDarkMode = theme.palette.mode === 'dark';

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const status = searchParams.get('status');
    if (status) {
      setShowResponseModal(true);
      setIsResponseSuccess(status === 'success');
    }
  }, [searchParams]);

  const handleResponseClose = () => {
    setShowResponseModal(false);
    setSearchParams({}, { replace: true });
  };

  const authTokenFromStorage = (async () => {
    try {
      const authToken = await getAccessToken();
      if (authToken) {
        return authToken;
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error:', error);
      return null;
    }
  })();

  (async () => {
    token = await authTokenFromStorage;
  })();

  useEffect(() => {
    if (activityId) {
      let response;
      const fetchActivitiesInfo = async () => {
        setLoading(true);
        if (isAuthenticated && localStorage.getItem('MyDetailsAccount')) {
          response = await dispatch(fetchActivityDetails(activityId));
        } else {
          response = await dispatch(fetchActivityDetailsUnauthorized(activityId));
        }

        if (response.meta.requestStatus === 'fulfilled') {
          setActivity(response.payload);
          setIsSubmitClicked(false);
        } else {
          setActivityNotFound(true);
          toast.error('This activity has been deleted', { id: 'activity-fetch-error' });
          toast.dismiss('activity-fetch-error');
        }
        setLoading(false);
      };
      fetchActivitiesInfo();
    }
  }, [
    activityId,
    isAuthenticated,
    volunteerButtonClick,
    isSubmitClicked,
    leaveActivityType,
    reportActivity,
    makeModeratorSuccess,
    blockedParticipant,
  ]);

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleSubCategoryClick = (category: string, subCategory: string) => {
    setCategory(category);
    setSubCategory(subCategory);
  };

  const handleAccordionClick = (categoryId: any, currentState: any) => {
    setCategory(categoryId);
  };

  const handleBookmarkSuccess = () => {
    setBookmarkSuccess(s => !s);
  };

  const handleLikeSuccess = () => {
    setLikemarkSuccess(s => !s);
  };

  const handleVolunteerButtonClick = value => {
    setvolunteerButtonClick(value);
  };

  const handleIsSubmitClicked = value => {
    setIsSubmitClicked(value);
  };

  const handleParticipantBlocked = value => {
    setblockedParticipant(value);
  };

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  }));

  const handleButtonMapClicked = () => {
    const newToggleMapValue = !toggleMapValue;
    if (toggleValue || toggleFeatureValue) {
      setToggleValue(false);
      setToggleFeatureValue(false);
    }
    setToggleMapValue(newToggleMapValue);
  };

  const drawer = (
    <Box
      sx={{
        border: 'none',
        height: '100vh',
        overflowY: 'scroll',
        overflowX: 'hidden',
        background:
          theme.palette.mode === 'dark'
            ? theme.palette.background.default
            : 'transparent linear-gradient(133deg, #F7F5EF 35%, #F2F2FA 58%, #EFEFF5 100%) 0% 0% no-repeat padding-box',

        maxWidth: { sm: drawerWidthSm, lg: drawerWidth, md: drawerWidthSm },
        backdropFilter: 'blur(10px)',

        '&::-webkit-scrollbar': {
          display: 'none',
        },
        '&::-webkit-scrollbar-thumb': { backgroundColor: 'secondary.contrastText', height: '10px' },
        '&::-webkit-scrollbar-track': {
          backgroundColor: 'transparent',
        },
      }}
    >
      <DrawerHeader sx={{ justifyContent: 'center', height: '79px', border: 'none' }}>
        <Box style={{ paddingLeft: '0px', paddingRight: '50px' }}>
          <Link to={'/'}>
            <AmyIcon sx={{ width: '159px', height: '100px' }} />
          </Link>
        </Box>
      </DrawerHeader>

      <SideNavigation
        category={category}
        subCategory={subCategory}
        onSubCategoryClick={handleSubCategoryClick}
        onhandleAccordionClick={handleAccordionClick}
        onButtonClicked={() => {}}
        onMapClicked={handleButtonMapClicked}
        toggleMapValue={toggleMapValue}
        setToggleMapValue={setToggleMapValue}
        onFeatureClicked={() => {}}
      />
      <Divider />
    </Box>
  );

  const container = window !== undefined ? () => window.document.body : undefined;

  const styles = {
    ...(isSmallScreen || breakPoint
      ? {
          border: 'none',
        }
      : {
          height: '85vh',
          overflow: 'unset',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          '&::-webkit-scrollbar-thumb': { backgroundColor: 'secondary.contrastText', height: '10px' },
          '&::-webkit-scrollbar-track': {
            backgroundColor: 'transparent',
          },
        }),
  };

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          background: isDarkMode ? '#1F1F26' : 'transparent linear-gradient(118deg, #F7F5EF 0%, #F2F2FA 49%, #EFEFF5 100%) 0% 0% no-repeat',
          p: 0,
          border: 'none',
          backdropFilter: 'blur(10px)',
          height: '100vh',
          overflow: 'hidden',
        }}
      >
        <CssBaseline />
        <AppBar
          elevation={0}
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidthSm}px)`, md: `calc(100% - ${drawerWidthSm}px)`, lg: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidthSm}px`, md: `${drawerWidthSm}px`, lg: `${drawerWidth}px` },
            background: isDarkMode ? '#2C2C38' : 'transparent',
          }}
        >
          <Toolbar
            className="amy-top-nav"
            sx={{
              backgroundColor: ' transparent linear-gradient(118deg, #F7F5EF 0%, #F2F2FA 49%, #EFEFF5 100%) 0% 0% no-repeat',
              background: isDarkMode ? '#1F1F26' : '',
              border: 'none',
              backdropFilter: 'blur(10px)',
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' }, top: '10px' }}
            >
              <MenuIcon />
            </IconButton>
            <PrimaryHeader searchKeyword={searchKeyword} setSearchKeyword={setSearchKeyword} />
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{
            width: { sm: drawerWidthSm, md: drawerWidthSm, lg: drawerWidth },
            flexShrink: { sm: 0 },
            minHeight: '100vh',
            border: 'none',
            background: isDarkMode
              ? '#1F1F26'
              : 'transparent linear-gradient(118deg, #F7F5EF 0%, #F2F2FA 49%, #EFEFF5 100%) 0% 0% no-repeat',
          }}
          aria-label="activity-detail"
        >
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidthSm },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: 'none', sm: 'block' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: { sm: drawerWidthSm, md: drawerWidthSm, lg: drawerWidth } },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          className="inside-bg"
          component="main"
          sx={{
            flexGrow: 1,
            maxWidth: '100%',

            p: 3,
            width: {
              sm: `calc(100% - ${drawerWidthSm} px)`,
              md: `calc(100% - ${drawerWidthSm} px)`,
              lg: `calc(100% - ${drawerWidth} px)`,
              background: isDarkMode
                ? '#1F1F26'
                : ' transparent linear-gradient(172deg, #F7F5EF 0%, #F2F2FA 49%, #EFEFF5 100%) 0% 0% no-repeat padding-box',
              border: 'none',
              backdropFilter: 'blur(10px)',
            },
          }}
        >
          <div className="row" style={{ padding: 1, marginTop: '50px' }}>
            <ArrowCircleLeftIcon
              onClick={() => handleGoBack()}
              sx={{ cursor: 'pointer', color: '#FFCE00', borderRadius: '50%', fontSize: '50px', marginLeft: '10px' }}
            />
          </div>

          <Box
            className="p-3"
            sx={{
              background: isDarkMode ? '#1F1F26' : '',
              height: '77vh',
              overflowY: 'scroll',
              overflowX: 'hidden',

              scrollbarWidth: 'none',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
            }}
          >
            <div className="row screen-space">
              <div className={`col-md-${breakPoint ? '12' : '8'}`} style={{ paddingLeft: '0px !important' }}>
                <Box sx={styles}>
                  {activityNotFound && (
                    <Box display="flex" height="60vh" flexDirection="column" alignItems="center" justifyContent="center">
                      <Box
                        component="img"
                        sx={{
                          background: isDarkMode ? '#1F1F26' : '',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          overflowX: 'hidden',
                          scrollbarWidth: 'none',
                          '&::-webkit-scrollbar': {
                            display: 'none',
                          },
                          maxHeight: { xs: 233, md: 167 },
                          maxWidth: { xs: 350, md: 250 },
                        }}
                        alt="The house from the offer."
                        src="content/images/empty.svg"
                      />
                      <Typography fontSize="22px" mt={3}>
                        Activity not found
                      </Typography>
                    </Box>
                  )}
                  <ActivityCardDetails
                    activityResponse={activity}
                    onBookmarkSuccess={handleBookmarkSuccess}
                    onLikeSuccess={handleLikeSuccess}
                    handleDeleteClick={async id => {
                      try {
                        const response = await dispatch(deleteActivityByIds({ activityIds: [id], organizationId: 0, message: '' }));
                        if (typeof response.payload === 'string') {
                          try {
                            if (authTokenFromStorage) {
                              dispatch(fetchActivities({ eventType: eventType }));
                              navigate('/');
                            } else {
                              dispatch(fetchunAuthorosiedActivities({ eventType: eventType }));
                            }
                          } catch (error) {
                            console.log(error);
                          }
                        }
                      } catch (error) {
                        console.log(error);
                      }
                    }}
                    onParticipantBlocked={handleParticipantBlocked}
                  />
                </Box>
                <PaymentResponseModal handleClose={handleResponseClose} open={showResponseModal} isSuccess={isResponseSuccess} />
              </div>
              <div className={`col-md-${breakPoint ? '12' : '4'}`}>
                <div
                  style={{
                    background: isDarkMode ? '#2C2C38' : '#FFFFFF',
                    borderRadius: '15px',
                    marginTop: isSmallScreen || breakPoint ? '2%' : '0',
                  }}
                >
                  <ActivityDetailsSidePanel
                    activityResponse={activity}
                    volunteerButtonClick={volunteerButtonClick}
                    isSubmitClicked={isSubmitClicked}
                    onVolunteerButtonClick={handleVolunteerButtonClick}
                    onIsSubmitClicked={handleIsSubmitClicked}
                  />
                </div>
              </div>
            </div>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default ActivitySection;
