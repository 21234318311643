import React, { useState, useEffect } from 'react';
import {
  Typography,
  Grid,
  Button,
  TextField,
  useTheme,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Stack,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import IconButton from '@mui/material/IconButton';
import './create-activity-resources.scss';
import { useParams } from 'react-router-dom';

const CreateActivityResources = ({ formik }: any) => {
  const [resources, setResources] = useState(formik.values.resources);
  const [openResources, setOpenResources] = useState(false);
  const [inputs, setInputs] = useState([]);
  const theme = useTheme();
  const { id } = useParams();

  const handleAddResources = () => {
    setOpenResources(s => !s);
    // setResources([]);
    if (resources === null) {
      setInputs([{ text: '', number: null, isEditing: true }]);
      return;
    }
    const initialInputs = resources?.map(resource => ({
      text: resource.resourceName,
      number: resource.quantity,
      isEditing: false,
    }));
    setInputs(initialInputs);
    setInputs([...initialInputs, { text: '', number: null, isEditing: true }]);
  };

  useEffect(() => {
    const updatedResources = inputs.map(input => {
      const { text, number } = input;
      return { resourceName: text, quantity: number };
    });

    formik.setFieldValue('resources', resources);
  }, [inputs]);

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;

    if (name === 'number') {
      // Ensure the value is a positive integer
      const newValue = parseInt(value, 10);
      const isValidValue = !isNaN(newValue) && newValue >= 1;

      const newInputs = [...inputs];
      newInputs[index][name] = isValidValue ? newValue : '';
      setInputs(newInputs);
    } else {
      const newInputs = [...inputs];
      newInputs[index][name] = value;
      setInputs(newInputs);
    }
  };

  const handleDeleteInput = index => {
    const newInputs = [...inputs];
    newInputs.splice(index, 1);
    setInputs(newInputs);
  };

  const handleToggleEdit = index => {
    const newInputs = [...inputs];
    newInputs[index].isEditing = !newInputs[index].isEditing;
    setInputs(newInputs);
    const filteredInputs = inputs.filter(input => {
      return input.text.trim() !== '' && input.number > 0;
    });
    const updatedResources = filteredInputs.map(input => ({
      resourceName: input.text,
      quantity: input.number,
    }));
    setResources(updatedResources);
    // formik.setFieldValue('resources', updatedResources);
  };
  const handleKeyDown = (index, e) => {
    if (e.key === 'Enter') {
      const newInputs = [...inputs];

      if (newInputs[index].text.trim() !== '' && newInputs[index].number > 0) {
        newInputs[index].isEditing = !newInputs[index].isEditing;
        setInputs(newInputs);
        const filteredInputs = inputs.filter(input => {
          return input.text.trim() !== '' && input.number > 0;
        });
        const updatedResources = filteredInputs.map(input => ({
          resourceName: input.text,
          quantity: input.number,
        }));
        setResources(updatedResources);
        formik.setFieldValue('resources', updatedResources);
        setInputs([...inputs, { text: '', number: '', isEditing: true }]);
      }
    }
  };
  const handleAddInput = () => {
    setInputs([...inputs, { text: '', number: '', isEditing: true }]);
  };
  const MemoizedTextField = React.memo(TextField);

  useEffect(() => {
    const updatedResources = inputs.map(input => {
      const { text, number } = input;
      return { resourceName: text, quantity: number };
    });
    // setResources(updatedResources);
    formik.setFieldValue('resources', resources);
  }, [inputs]);

  const handleResourcesClose = () => {
    const filteredInputs = inputs.filter(input => {
      return input.text.trim() !== '' && input.number > 0;
    });
    const updatedResources = filteredInputs.map(input => ({
      resourceName: input.text,
      quantity: input.number,
    }));
    setResources(updatedResources);
    formik.setFieldValue('resources', updatedResources);
    setOpenResources(false);
  };
  useEffect(() => {
    if (id !== undefined && formik.values.resources) {
      setResources(formik.values.resources);
    }
  }, [formik.values.resources]);
  const isDarkMode1 = theme.palette.mode === 'dark';
  const input = document.querySelector('input[name="number"]');

  if (input) {
    input.addEventListener('wheel', function (event) {
      event.preventDefault();
    });
  }

  const handleWheel = e => {
    // Prevent scrolling when the TextField is focused
    const activeElement = document.activeElement as HTMLInputElement;
    if (activeElement.tagName === 'INPUT' && activeElement.type === 'number') {
      e.preventDefault();
    }
  };

  return (
    <Grid item>
      <div
        style={{
          borderRadius: '16px',
          padding: 12,
          height: '210px',
          overflowX: 'hidden',
          overflowY: 'hidden',
          backgroundColor: isDarkMode1 ? '#27272E' : '#ffff',
        }}
      >
        <Typography fontWeight={600} color={'secondary.main'}>
          Resources needed
        </Typography>

        <div style={{ maxHeight: '130px', overflowY: 'hidden', display: 'flex', flexDirection: 'column' }}>
          {resources?.length > 0 && (
            <TableContainer className="scrollable-div example1">
              <Table key="index">
                <React.Fragment>
                  <TableHead style={{ position: 'sticky', top: 0, backgroundColor: isDarkMode1 ? '#27272E' : '#ffff', zIndex: 1 }}>
                    <TableRow>
                      <TableCell>S.No</TableCell>
                      <TableCell>Resource Name</TableCell>
                      <TableCell>Quantity</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody style={{ flex: 1, overflowY: resources && resources?.length * 20 > 19 ? 'scroll' : 'hidden' }}>
                    {resources?.map(
                      (input, index) =>
                        input.resourceName?.trim() !== '' &&
                        resources?.length > 0 && (
                          <TableRow key={index}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>
                              <Typography
                                sx={{
                                  whiteSpace: 'pre-wrap',
                                  wordWrap: 'break-word',
                                  maxWidth: ' 80px',
                                  fontSize: '14px',
                                }}
                              >
                                {input.resourceName}
                              </Typography>
                            </TableCell>
                            <TableCell>{input.quantity}</TableCell>
                          </TableRow>
                        )
                    )}
                  </TableBody>
                </React.Fragment>
              </Table>
            </TableContainer>
          )}
        </div>

        <IconButton aria-label="add" onClick={handleAddResources} disabled={formik.values.volunteerParticipant > 1}>
          <AddCircleOutlineOutlinedIcon sx={{ color: 'primary.main' }} />
        </IconButton>
      </div>

      <Dialog
        open={openResources}
        onClose={handleResourcesClose}
        PaperProps={{
          style: {
            maxWidth: '100%',
            minWidth: '70%',
            height: '63vh',
            overflowX: 'hidden',
            overflowY: 'scroll',
            borderRadius: '16px',
          },
        }}
      >
        <DialogTitle pb={1} id="alert-dialog-title">
          {'Add your resources'}
        </DialogTitle>
        <CloseIcon onClick={handleResourcesClose} sx={{ cursor: 'pointer', position: 'absolute', right: 15, top: 20 }} />
        <Typography pb={2} sx={{ color: theme.textColor.paragraph, px: '24px' }}>
          Estimating the type and quantities of material, people, equipment or supplies required to perform each activity
        </Typography>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {
              <div>
                {inputs?.map((input, index) => (
                  <Grid container spacing={1} alignItems="center" justifyContent="space-between" key={index} sx={{ my: 0.5 }}>
                    <Grid item xs={1} lg={1}>
                      <Typography sx={{ fontSize: '18px', fontWeight: 400 }}>{index + 1}</Typography>
                    </Grid>
                    <Grid item xs={6} lg={7}>
                      <TextField
                        size="small"
                        fullWidth
                        multiline
                        rows={1}
                        placeholder="item"
                        name="text"
                        value={input?.text}
                        onChange={e => handleInputChange(index, e)}
                        disabled={!input.isEditing}
                        onKeyDown={e => handleKeyDown(index, e)}
                      />
                    </Grid>
                    <Grid item xs={3} lg={2} onWheel={handleWheel}>
                      <TextField
                        size="small"
                        fullWidth
                        type="text"
                        name="number"
                        placeholder="qty"
                        value={input.number}
                        onChange={e => handleInputChange(index, e)}
                        disabled={!input.isEditing}
                        inputProps={{
                          inputMode: 'numeric',
                          pattern: '[0-9]*',
                          maxLength: 7,
                        }}
                        onKeyDown={e => handleKeyDown(index, e)}
                      />
                    </Grid>
                    <Grid item container alignItems={'center'} justifyContent={'space-between'} xs={2} lg={2}>
                      {input.isEditing ? (
                        <Stack direction="row" gap={1}>
                          {/* <SaveIcon
                            onClick={() => handleToggleEdit(index)}
                            sx={{
                              cursor: 'pointer',
                              color: 'primary.light',
                              opacity: input.text && input.number ? 1 : 0.5,
                              pointerEvents: input.text && input.number ? 'auto' : 'none',
                            }}
                          /> */}
                          <DeleteIcon
                            onClick={() => handleDeleteInput(index)}
                            sx={{
                              cursor: 'pointer',
                              color: 'primary.light',
                              opacity: input.text && input.number ? 1 : 0.5,
                              pointerEvents: input.text && input.number ? 'auto' : 'none',
                            }}
                          />
                        </Stack>
                      ) : (
                        <EditIcon
                          onClick={() => handleToggleEdit(index)}
                          sx={{
                            cursor: 'pointer',
                            color: 'primary.light',
                            opacity: input.text && input.number ? 1 : 0.5,
                            pointerEvents: input.text && input.number ? 'auto' : 'none',
                          }}
                        />
                      )}
                    </Grid>
                  </Grid>
                ))}
              </div>
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <div className="text-center pt-3 w-100 d-flex justify-content-end">
            {inputs.some(input => input.text.trim() === '' || input.number === 0) ? (
              <Button
                type="submit"
                variant="contained"
                style={{ backgroundColor: '#674BFF' }}
                sx={{
                  color: 'secondary.dark',
                  textTransform: 'none',
                  borderRadius: '15px',
                  width: '27%',
                  marginTop: '6px',
                  height: '40px',
                  '&:hover': {
                    backgroundColor: 'secondary.contrastText',
                    color: 'secondary.dark',
                  },
                }}
                disabled
              >
                Add
              </Button>
            ) : (
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: '#674BFF',
                  color: '#fff',
                  textTransform: 'none',
                  borderRadius: '15px',
                  width: '27%',
                  marginTop: '6px',
                  height: '40px',
                  '&:hover': {
                    backgroundColor: '#674BFF',
                    color: '#fff',
                  },
                }}
                onClick={handleAddInput}
              >
                Add
              </Button>
            )}
          </div> */}
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default CreateActivityResources;
