import React, { useEffect, useState, useContext } from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import { Box, IconButton, InputAdornment, OutlinedInput, Skeleton, Stack, useMediaQuery, useTheme } from '@mui/material';
import SearchIcon from 'app/components/icons/header-icons/serach-icon';
import { useNavigate } from 'react-router-dom';
import './chat-card.scss';
import { getAllEvents, getAllEventsById } from 'app/shared/reducers/chat-messages/left-panel';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import './chat-cardleftnav.scss';
import { updateChatList } from 'app/shared/reducers/chat-messages/chat-store/chat-list';
import { onForegroundMessage } from 'app/firebase';
import axios from 'axios';
import { windowHeight } from './chat-card';
import WebSocketContext from './WebSocketContext';
import { setConversations } from 'app/shared/reducers/chat-messages/chat-Panel';

type ChatCardleftnavProps = {
  selectChat: (data: any) => void;
};

const ChatCardleftnav: React.FC<ChatCardleftnavProps> = ({ selectChat }) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const [searchQuery, setSearchQuery] = useState('');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const getAllChatResponse = useAppSelector(state => state.leftPanelReducer.chatResponse);
  const getNewMessage = useAppSelector(state => state.chatSentReducer.chatResponse);
  const [selectedChat, setSelectedChat] = useState(null);
  const [filteredChatList, setFilteredChatList] = useState([]);
  const isMobile = useMediaQuery(theme.breakpoints.down(575));
  const navigateItem = chatItem => {
    if (chatItem?.id !== selectedChat?.id) {
      let res = dispatch(setConversations([]));
      if (res) {
        selectChat(chatItem);
        if (!isMobile) {
          handleSelection(chatItem);
          removeDot(chatItem);
        } else {
          handleSelection(chatItem);
          removeDot(chatItem);
          navigate('/chat');
        }
      }
    }
  };

  const [prevLocalStorageValue, setPrevLocalStorageValue] = useState(localStorage.getItem('datKey'));

  useEffect(() => {
    if (getAllChatResponse?.chatDetails && !localStorage.getItem('dataKey')) {
      const check = getAllChatResponse.chatDetails
        .filter(chatItem => chatItem?.title?.toLowerCase().includes(searchQuery.toLowerCase()))
        .sort((a, b) => {
          const dateA = new Date(a?.chatLastMessage?.message ? a?.chatLastMessage?.messageAt : a?.eventCreationTime);
          const dateB = new Date(b?.chatLastMessage?.message ? b?.chatLastMessage?.messageAt : b?.eventCreationTime);

          return dateB.getTime() - dateA.getTime();
        });

      const currentItem = check[0];
      if (currentItem) {
        setSelectedChat(currentItem);
        handleSelection(currentItem);
      }
      setFilteredChatList(check);
      // handleCallForeground()
    }

    if (getAllChatResponse?.chatDetails && localStorage.getItem('dataKey')) {
      const sortedChatDetails = [...getAllChatResponse.chatDetails].sort((a, b) => {
        const pinA = a.pinTop ? 1 : 0;
        const pinB = b.pinTop ? 1 : 0;
        return pinB - pinA;
      });

      const dataKeyItem = JSON.parse(localStorage.getItem('dataKey'));
      const currentItem = sortedChatDetails?.find(mockItem => mockItem.id === dataKeyItem.id);
      if (currentItem) {
        setSelectedChat(currentItem);
        handleSelection(currentItem);
      }
      setFilteredChatList(sortedChatDetails);
    }
  }, [getAllChatResponse, searchQuery, localStorage.getItem('dataKey')]);

  const handleCallForeground = async () => {
    try {
      const axiosResponse = await axios.get<{ chatDetails: any[]; chatConversations: any[] }>('/api/chats/getAllEvents');
      if (axiosResponse && axiosResponse.data && axiosResponse.data.chatDetails) {
        const chatDetails = axiosResponse.data.chatDetails;
        setFilteredChatList(chatDetails);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    localStorage.removeItem('getChatSide');
  }, []);

  useEffect(() => {
    if (getAllChatResponse && filteredChatList) {
      const checkMock = getAllChatResponse?.chatDetails?.slice().sort((a, b) => {
        const dateA = new Date((a?.chatLastMessage?.message ? a?.chatLastMessage?.messageAt : a?.eventCreationTime) as string);
        const dateB = new Date((b?.chatLastMessage?.message ? b?.chatLastMessage?.messageAt : b?.eventCreationTime) as string);
        return dateB.getTime() - dateA.getTime();
      });

      if (localStorage.getItem('dataKey')) {
        const dataKeyItem = JSON.parse(localStorage.getItem('dataKey'));
        const sortedChatDetails = [...getAllChatResponse.chatDetails].sort((a, b) => {
          const pinA = a.pinTop ? 1 : 0;
          const pinB = b.pinTop ? 1 : 0;
          return pinB - pinA;
        });
        const currentItem = filteredChatList?.find(mockItem => mockItem.id === dataKeyItem.id);
        if (currentItem) {
          localStorage.setItem('getChatData', JSON.stringify(dataKeyItem));
          setSelectedChat(currentItem);
          handleSelection(currentItem);
          localStorage.setItem('getChatData', JSON.stringify(currentItem));
        }
      } else {
        console.log('check else');
        localStorage.removeItem('getChatData');
        const storedValue = getAllChatResponse[0];
        if (storedValue !== null && storedValue !== undefined) {
          try {
            setSelectedChat(JSON.parse(storedValue));
          } catch (error) {
            console.error('Error parsing JSON:', error);
            setSelectedChat(checkMock[0]);
          }
        } else {
          setSelectedChat(checkMock[0]);
        }
      }

      if (localStorage.getItem('getChatSide') !== JSON.stringify({ data: 'noDataHere' })) {
        const chatDetails = getAllChatResponse.chatDetails;

        if (chatDetails && chatDetails.length > 0) {
          const getChatSide = chatDetails[0];
          const check = chatDetails.slice().sort((a, b) => {
            const dateA = new Date((a?.chatLastMessage?.message ? a?.chatLastMessage?.messageAt : a?.eventCreationTime) as string);
            const dateB = new Date((b?.chatLastMessage?.message ? b?.chatLastMessage?.messageAt : b?.eventCreationTime) as string);
            return dateB.getTime() - dateA.getTime();
          });
          const localStorageChatData = localStorage.getItem('getChatData');

          if (localStorageChatData !== null && localStorageChatData !== undefined) {
          } else {
            localStorage.setItem('getChatData', JSON.stringify(check[0]));
          }
          if (!localStorage.getItem('getChatSide')) {
            handleSelection(JSON.parse(localStorage.getItem('getChatData')));
            localStorage.setItem('getChatSide', JSON.stringify({ data: 'yourDataHere' }));
          } else if (localStorage.getItem('getChatData')) {
            handleSelection(JSON.parse(localStorage.getItem('getChatData')));
            setSelectedChat(JSON.parse(localStorage.getItem('getChatData')));
            localStorage.setItem('getChatSide', JSON.stringify({ data: 'noDataHere' }));
          }
        }
      }
    }
  }, [filteredChatList]);

  /**
   * selecton of list item and passing to the parent chat component
   */
  const removeDot = data => {
    setSelectedChat(data);
    const selectedIndex = filteredChatList?.findIndex(item => item.id === data.id);
    if (selectedIndex !== -1) {
      filteredChatList[selectedIndex] = {
        ...filteredChatList[selectedIndex],
        unreadMessageCount: 0,
      };
    }
    setFilteredChatList(filteredChatList);
  };
  const handleSelection = data => {
    localStorage.setItem('getChatData', JSON.stringify(data));
    let targetResult = filteredChatList?.filter(item => {
      return item.id === data.id;
    });
    dispatch(updateChatList(targetResult));
  };
  const { disconnect } = useContext(WebSocketContext);
  const handleBack = () => {
    disconnect();
    navigate('/');
  };

  return (
    <Stack
      direction={'column'}
      className="pl-3 "
      sx={{
        justifyContent: 'unset',
        borderRadius: '15px',
        marginLeft: { xs: 0, md: '17%' },
        height: `calc(${windowHeight}px - 92px)`,
        overflowY: 'scroll',
        overflowX: 'hidden',
        background: isDarkMode
          ? '#1F1F26'
          : 'transparent linear- gradient(109deg, #D0CEC3 25%, #F2F2FA 0 %, #EFEFF5 80 %) 0% 0 % no - repeat padding- box',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        '&::-webkit-scrollbar-thumb': {
          background: 'transparent linear- gradient(109deg, #D0CEC3 25%, #F2F2FA 0 %, #EFEFF5 80 %) 0% 0 % no - repeat padding- box',
          height: '10px',
        },
        '&::-webkit-scrollbar-track': {
          background: 'transparent linear- gradient(109deg, #D0CEC3 25%, #F2F2FA 0 %, #EFEFF5 80 %) 0% 0 % no - repeat padding- box',
        },
      }}
    >
      <span onClick={handleBack}>
        <ArrowCircleLeftIcon
          sx={{ cursor: 'pointer', color: '#FFCE00', borderRadius: '50%', fontSize: '42px', paddingLeft: '0px', paddingRight: '12px' }}
        />
      </span>
      <FormControl className="mb-2 mob-searchbar">
        <OutlinedInput
          className="form-width"
          sx={{
            height: '40px',
            width: 'inherit',
            color: 'secondary.main',
            border: isDarkMode ? '#ff5500' : 'none',
            '& .MuiOutlinedInput-input::placeholder': {
              color: 'secondary.contrastText',
              fontSize: '12px',
            },
            '&.MuiOutlinedInput-root': {
              background: isDarkMode ? theme.palette.background.default : '#FCFCFC',
              borderRadius: '14px',
              '@media (max-width: 575px)': {
                borderRadius: '6px',
              },
            },
            '& fieldset': {
              borderColor: isDarkMode ? '1px solid #34343B' : '#FCFCFC',
            },
            '@media (max-width: 575px)': {
              borderRadius: '6px',
            },
          }}
          size="small"
          id="searchBar"
          type={'text'}
          placeholder="Search Messages"
          onChange={e => setSearchQuery(e.target.value)} // Update searchQuery state on input change
          endAdornment={
            <InputAdornment position="end">
              <IconButton aria-label="search button" edge="end">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      <List
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          maxWidth: 360,
          backgroundColor: 'secondary.dark',
          pt: 0,
          overflow: 'hidden',
          borderRadius: '12px',
          flex: 1,
          '@media (max-width: 575px)': {
            maxWidth: 'unset',
            borderRadius: '5px',
          },
        }}
      >
        <Box sx={{ flex: 1, overflow: 'auto' }}>
          {filteredChatList?.length > 0 ? (
            filteredChatList.map(chatItem => (
              <React.Fragment key={chatItem.id}>
                <ListItemButton
                  alignItems="flex-start"
                  onClick={() => navigateItem(chatItem)}
                  sx={{ backgroundColor: chatItem?.id === selectedChat?.id ? (!isDarkMode ? '#e0e0e0' : '#6f6f6f') : '' }}
                >
                  <ListItemText
                    primary={
                      <Typography sx={{ display: 'inline', fontSize: '14px', fontWeight: 'bold', fontFamily: 'Manrope' }}>
                        <p style={{ marginBottom: '10px', width: '97%' }}>{chatItem?.title}</p>
                      </Typography>
                    }
                    secondary={
                      <div className="d-flex justify-content-between">
                        <div className="p-2">
                          {chatItem?.chatLastMessage?.message ? (
                            <small>{moment(chatItem?.chatLastMessage?.messageAt).fromNow()}</small>
                          ) : (
                            <small>{moment(chatItem?.eventCreationTime).fromNow()}</small>
                          )}
                        </div>

                        <div className="ml-auto p-2">
                          <small style={{ marginLeft: chatItem?.chatLastMessage?.message ? '50px' : '125px', display: 'contents' }}>
                            {chatItem?.peopleCount} {chatItem?.peopleCount > 1 ? 'People' : 'Person'}
                          </small>
                        </div>
                      </div>
                    }
                  />
                  {chatItem?.unreadMessageCount > 0 && chatItem?.id !== selectedChat?.id ? (
                    <span
                      className="chat-count"
                      style={{ background: isDarkMode ? '#FFCE00' : '#674BFF', color: isDarkMode ? '#000' : '#fff' }}
                    >
                      {chatItem?.unreadMessageCount}
                    </span>
                  ) : null}
                </ListItemButton>
                <Divider variant="inset" component="li" sx={{ marginLeft: '0px' }} />
              </React.Fragment>
            ))
          ) : (
            <>
              <Typography
                sx={{ textAlign: 'center', left: '30%', fontSize: '16px', color: 'text.secondary', top: '40%', position: 'absolute' }}
              >
                No result found
              </Typography>
              <Skeleton variant="rounded" width={250} height={550} sx={{ position: 'relative' }} />
            </>
          )}
        </Box>
      </List>
    </Stack>
  );
};

export default React.memo(ChatCardleftnav);
