import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { recurringType, singleActivityType } from 'app/utils/types/activity/activity-types';
import { ActivityResourceRequest } from 'app/utils/types/activity/resource-types';
import toast from 'react-hot-toast';
import axios from 'axios';
import { LeaveActivity } from './activity/activity-actions';

export interface FetchActivitiesArgumentsTypes {
  sortType: string;
  eventType: string;
}

export interface initialActivityState {
  activities: singleActivityType[];
  loading: boolean;
  error: string | null;
  sortType: string;
  eventType: string;
  singleActivity: singleActivityType;
  activityDetails: any;
}

export const initialState: initialActivityState = {
  activities: [],
  loading: false,
  error: null,
  sortType: 'World',
  eventType: 'Latest',
  singleActivity: null,
  activityDetails: null,
};

export const fetchActivities = createAsyncThunk<singleActivityType[], { eventType: string }, { rejectValue: string }>(
  'activity/fetchactivityByType',
  async ({ eventType }, { rejectWithValue }) => {
    const body = {
      eventType: eventType,
    };

    try {
      const axiosResponse = await axios.post<singleActivityType[]>('api/home/filter', body);
      return axiosResponse.data;
    } catch (error) {
      throw error.response?.data?.message || 'An error occurred';
    }
  }
);
export const fetchunAuthorosiedActivities = createAsyncThunk<singleActivityType[], { eventType: string }, { rejectValue: string }>(
  'activity/fetchactivityByType',
  async ({ eventType }, { rejectWithValue }) => {
    const body = {
      eventType: eventType,
    };

    try {
      const axiosResponse = await axios.post<singleActivityType[]>('api/home/filter', body);
      return axiosResponse.data;
    } catch (error) {
      throw error.response?.data?.message || 'An error occurred';
    }
  }
);
export const fetchNearMe = createAsyncThunk<
  singleActivityType[],
  { eventType: string; latitude: string; longitude: string },
  { rejectValue: string }
>('activity/fetchactivityByType', async ({ eventType, latitude, longitude }, { rejectWithValue }) => {
  const body = {
    filter: {
      nearMe: {
        latitude: latitude,
        longitude: longitude,
      },
    },
    eventType: eventType,
  };

  try {
    const axiosResponse = await axios.post<singleActivityType[]>('api/home/filter', body);
    return axiosResponse.data;
  } catch (error) {
    throw error.response?.data?.message || 'An error occurred';
  }
});

export const fetchMyActivities = createAsyncThunk<singleActivityType[], { eventType: string }, { rejectValue: string }>(
  'activity/fetchactivityByType',
  async ({ eventType }, { rejectWithValue }) => {
    const body = {
      filter: {
        mySelf: true,
      },
      eventType: eventType,
    };

    try {
      const axiosResponse = await axios.post<singleActivityType[]>('api/home/filter', body);
      return axiosResponse.data;
    } catch (error) {
      throw error.response?.data?.message || 'An error occurred';
    }
  }
);

export const fetchMyAuthorosiedActivities = createAsyncThunk<singleActivityType[], { eventType: string }, { rejectValue: string }>(
  'activity/fetchactivityByType',
  async ({ eventType }, { rejectWithValue }) => {
    const body = {
      filter: {
        mySelf: true,
      },
      eventType: eventType,
    };

    try {
      const axiosResponse = await axios.post<singleActivityType[]>('api/home/filter', body);
      return axiosResponse.data;
    } catch (error) {
      throw error.response?.data?.message || 'An error occurred';
    }
  }
);

export const deleteActivityById = createAsyncThunk<any, { id: string }, { rejectValue: string }>('activity/deleteById', async ({ id }) => {
  try {
    const axiosResponse = await axios.delete<singleActivityType[]>(`/api/activities/${id}`);
    return axiosResponse.data;
  } catch (error) {
    throw error.response?.data?.message || 'An error occurred';
  }
});

export const deleteActivityByIds = createAsyncThunk<
  any,
  { activityIds: any; organizationId: number; message: string },
  { rejectValue: string }
>('activity/deleteByIds', async ({ activityIds, organizationId, message }) => {
  try {
    const axiosResponse = await axios.delete<any>(`/api/activities`, {
      data: {
        activityIds: activityIds,
        organizationId: organizationId,
        message: message,
      },
    });
    toast.success(axiosResponse?.data);
    return axiosResponse.data;
  } catch (error) {
    throw error.response?.data?.message || 'An error occurred';
  }
});

export const fetchActivityById = createAsyncThunk<singleActivityType, string, { rejectValue: string }>(
  'activity/fetchActivityById',
  async (id, { rejectWithValue }) => {
    try {
      const axiosResponse = await axios.get<singleActivityType>(`api/activities/${id}`);
      return axiosResponse.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'An error occurred');
    }
  }
);

export const updateActivityById = createAsyncThunk<any, { activity: any; id: string }, { rejectValue: string }>(
  'activity/updateById',
  async ({ activity, id }) => {
    try {
      const axiosResponse = await axios.put<any>(`api/activities/${id}`, activity);
      return axiosResponse.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const fetchActivityDetails = createAsyncThunk<singleActivityType, number>('activity/fetchActivityDetails', async id => {
  try {
    const response = await axios.get<singleActivityType>(`/api/activity-details/${id}`);
    return response.data;
  } catch (error) {}
});

export const fetchActivityDetailsUnauthorized = createAsyncThunk<singleActivityType, number>(
  'activity/fetchActivityDetailsUnauthorized',
  async id => {
    try {
      const response = await axios.get<singleActivityType>(`/api/unauthorized/activity-details/${id}`);
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || 'An error occurred');
    }
  }
);

export const fetchPetitionDetails = createAsyncThunk<singleActivityType, number>('activity/fetchPetitionDetails', async id => {
  try {
    const response = await axios.get<singleActivityType>(`/api/petition/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'An error occurred');
  }
});

export const fetchRecurringDetails = createAsyncThunk<recurringType, number>('activity/recurring-details', async id => {
  try {
    const response = await axios.get<recurringType>(`/api/activity/recurring-details/${id}?filterType=ALL`);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'An error occurred');
  }
});

export const fetchUnAuthRecurringDetails = createAsyncThunk<recurringType, number>('activity/recurring-details', async id => {
  try {
    const response = await axios.get<recurringType>(`/api/unauthorized/activity/recurring-details-filter/${id}?filterType=ALL`);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'An error occurred');
  }
});
export const fetchRecurringDetailsFilter = createAsyncThunk<recurringType, { id: number; filterType: string }>(
  'activity/recurring-details',
  async ({ id, filterType }) => {
    try {
      const response = await axios.get<recurringType>(`/api/activity/recurring-details/${id}?filterType=${filterType.toUpperCase()}`);
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || 'An error occurred');
    }
  }
);

export const fetchUnauthorizedPetitionDetails = createAsyncThunk<singleActivityType, number>('activity/fetchPetitionDetails', async id => {
  try {
    const response = await axios.get<singleActivityType>(`/api/unauthorized/petition/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'An error occurred');
  }
});
export const postResourceDetails = createAsyncThunk<any, ActivityResourceRequest, { rejectValue: string }>(
  '/api/activity-details/contribute-participants',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post<any>('api/activity-details/contribute-participants', payload);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'An error occurred');
    }
  }
);

export const fetchRemoteActivities = createAsyncThunk<any[], { eventType: string }, { rejectValue: string }>(
  'activity/fetchRemoteActivitiesByType',
  async ({ eventType }, { rejectWithValue }) => {
    const body = {
      filter: {
        mySelf: true,
        remoteEvents: true,
      },
      eventType: eventType,
    };

    try {
      const axiosResponse = await axios.post<any[]>('api/home/filter', body);
      return axiosResponse.data;
    } catch (error) {
      throw error.response?.data?.message || 'An error occurred';
    }
  }
);

export const fetchUnauthorizedRemoteActivities = createAsyncThunk<any[], { eventType: string }, { rejectValue: string }>(
  'activity/fetchUnauthorizedRemoteActivitiesByType',
  async ({ eventType }, { rejectWithValue }) => {
    const body = {
      filter: {
        remoteEvents: true,
      },
      eventType: eventType,
    };

    try {
      const axiosResponse = await axios.post<any[]>('api/unauthorized/home/filter', body);
      return axiosResponse.data;
    } catch (error) {
      throw error.response?.data?.message || 'An error occurred';
    }
  }
);

export const fetchActivityShareToken = createAsyncThunk<any, { activityId: number; type: number }, { rejectValue: string }>(
  'activity/fetchActivityShareToken',
  async ({ activityId, type }, thunkAPI) => {
    try {
      const response = await axios.get(`/api/activity/share/token?activityId=${activityId}&type=${type}`);

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.message || 'Failed to fetch data');
    }
  }
);
export const fetchBlogShareToken = createAsyncThunk<any, { blogId: number; type: number }, { rejectValue: string }>(
  'activity/fetchBlogShareToken',
  async ({ blogId, type }, thunkAPI) => {
    try {
      const response = await axios.get(`/api/blog/share/token?blogId=${blogId}&type=${type}`);

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.message || 'Failed to fetch data');
    }
  }
);

export const fetchPetitionShareToken = createAsyncThunk<any, { activityId: number; type: number }, { rejectValue: string }>(
  'activity/fetchPetitionShareToken',
  async ({ activityId, type }, thunkAPI) => {
    try {
      const response = await axios.get(`/api/petition/share/token?petitionId=${activityId}&type=${type}`);

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.message || 'Failed to fetch data');
    }
  }
);
export const trackActivityShare = createAsyncThunk<any, { activityId: number; token: string }, { rejectValue: string }>(
  'activity/trackActivityShare',
  async ({ activityId, token }, thunkAPI) => {
    try {
      const response = await axios.post(`/api/activity/share/track?activityId=${activityId}&token=${token}`);

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.message || 'Failed to fetch data');
    }
  }
);

export const trackPetitionShare = createAsyncThunk<any, { petitionId: number; token: string }, { rejectValue: string }>(
  'activity/trackActivityShare',
  async ({ petitionId, token }, thunkAPI) => {
    try {
      const response = await axios.post(`/api/petition/share/track?petitionId=${petitionId}&token=${token}`);

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.message || 'Failed to fetch data');
    }
  }
);

export const trackBlogShare = createAsyncThunk<any, { blogId: number; token: string }, { rejectValue: string }>(
  'activity/trackActivityShare',
  async ({ blogId, token }, thunkAPI) => {
    try {
      const response = await axios.post(`/api/blog/share/track?blogId=${blogId}&token=${token}`);

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.message || 'Failed to fetch data');
    }
  }
);

export const activitySlice = createSlice({
  name: 'homeActivity',
  initialState,
  reducers: {
    changeSortType: (state, action: PayloadAction<string>) => {
      state.sortType = action.payload;
    },
    changeEventType: (state, action: PayloadAction<string>) => {
      state.eventType = action.payload;
    },
    removeSingleActivity: state => {
      state.singleActivity = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchActivities.pending, state => {
        state.loading = true;
        state.error = null;
        state.activities = null;
      })
      .addCase(fetchActivities.fulfilled, (state, action: PayloadAction<singleActivityType[]>) => {
        state.loading = false;
        state.activities = action.payload;
      })
      .addCase(fetchActivities.rejected, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteActivityById.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteActivityById.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.activities = state.activities.filter(activity => activity.id !== action.payload.id);
      })
      .addCase(deleteActivityById.rejected, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchActivityById.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchActivityById.fulfilled, (state, action: PayloadAction<singleActivityType>) => {
        state.loading = false;
        state.singleActivity = action.payload;
      })
      .addCase(fetchActivityById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(fetchActivityDetails.pending, state => {
        state.loading = true;
        state.error = null;
        state.activityDetails = [];
      })
      .addCase(fetchActivityDetails.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.activityDetails = action.payload;
      })
      .addCase(fetchActivityDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(fetchActivityDetailsUnauthorized.pending, state => {
        state.loading = true;
        state.error = null;
        state.activityDetails = [];
      })
      .addCase(LeaveActivity.fulfilled, (state, action) => {
        const argId = action.meta.arg.activityIds[0];
      })
      .addCase(fetchActivityDetailsUnauthorized.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.activityDetails = action.payload; // Update the singleActivity state with fetched data
      })
      .addCase(fetchActivityDetailsUnauthorized.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(fetchRemoteActivities.pending, state => {
        state.loading = true;
        state.error = null;
        state.activities = null;
      })
      .addCase(fetchRemoteActivities.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.activities = action.payload;
      })
      .addCase(fetchRemoteActivities.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(fetchUnauthorizedRemoteActivities.pending, state => {
        state.loading = true;
        state.error = null;
        state.activities = null;
      })
      .addCase(fetchUnauthorizedRemoteActivities.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.activities = action.payload;
      })
      .addCase(fetchUnauthorizedRemoteActivities.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});
export default activitySlice.reducer;
export const { changeSortType, changeEventType, removeSingleActivity } = activitySlice.actions;
