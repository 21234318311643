import { FormControl, FormHelperText, Grid, MenuItem, Select, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { fetchOrganization } from 'app/shared/reducers/organization';
import { setSelectedOrgId } from 'app/shared/reducers/settings/settings-organization';
import React, { useEffect } from 'react';

const SettingsOrgname = () => {
  const userOwnedOrganizations = useAppSelector(state => state.organizationReducer.userOwnedOrganization);

  const { selectedOrgId } = useAppSelector(state => state.settingsOrganization);
  const dispatch = useAppDispatch();

  const handleOrgChange = event => {
    const choosenId = event.target.value;
    dispatch(setSelectedOrgId(choosenId));
    fetchOrganizationData(choosenId);
    localStorage.setItem('organizationId', choosenId);
  };

  const fetchOrganizationData = async id => {
    try {
      await dispatch(fetchOrganization(id));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Grid container spacing={2} sx={{ alignItems: 'center' }}>
      <Grid item xs={12} md={4}>
        <Typography variant="h5" sx={{ fontSize: '14px' }}>
          Select Organization
        </Typography>
      </Grid>
      <Grid display="flex" item xs={12} md={8}>
        <FormControl sx={{ minWidth: '100%' }} size="small">
          <Select
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            value={selectedOrgId}
            onChange={handleOrgChange}
            sx={{ fontSize: '0.75rem', padding: '3.5px 0px' }}
          >
            {userOwnedOrganizations
              ?.filter(item => !item.deleted)
              .map(item => (
                <MenuItem value={item.id} key={item.id}>
                  {item.label}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default SettingsOrgname;
