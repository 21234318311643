import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Paper,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from 'app/config/store';
import { Link } from 'react-router-dom';
import {
  generateGMapsLinkCords,
  transformDate,
  calculateDistance,
  getAccessToken,
  convertDateToYear,
} from 'app/utils/common-utils/common-utils';
import CloseIcon from '@mui/icons-material/Close';
import { participateInActivity, participateInActivityCard } from 'app/shared/reducers/volunteer';
import ActivityContribute from 'app/components/ui/activity-details-section/activity-resource/activity-contribute';
import { isBefore, isEqual, differenceInMinutes, parseISO } from 'date-fns';
import { announceArrival } from 'app/shared/reducers/announce-arrival';
import toast from 'react-hot-toast';
import AllContributionsListModal from 'app/components/common/modals/all-contributions-list-modal/all-contributions-list-modal';
import AuthorisationModal from 'app/components/common/authorization-modal/authorization-modal';
import './activity-chat-sidebar.scss';
import Cookies from 'js-cookie';
import JoiningSuccessModal from 'app/components/common/modals/joining-success-modal';
import { toggleModalLogin } from 'app/shared/reducers/authentication';
import { getAllEvents, getAllEventsById } from 'app/shared/reducers/chat-messages/left-panel';
import { getAllConversationsById } from 'app/shared/reducers/chat-messages/chat-Panel';
import RadioDialog from 'app/components/common/alert-dialog/radio-dialog';
import { fetchActivityDetails, fetchActivityDetailsUnauthorized, fetchRecurringDetails } from 'app/shared/reducers/activity-home';
import RecurringRangeModal from '../activity-recurring-section/recurring-date-range/recurring-range-modal';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import axios from 'axios';
import GenericModal from 'app/components/common/modals/generic-modal';
import { fetchBanned, fetchBlocked } from 'app/shared/reducers/profile';

const ActivityChatSideBar = ({
  activityResponseParent,
  volunteerButtonClick,
  isSubmitClicked,
  onVolunteerButtonClick,
  onIsSubmitClicked,
}) => {
  const [activityResponse, setactivityResponse] = useState<any>(activityResponseParent);
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isDarkMode = theme.palette.mode === 'dark';
  const [activityLocation, setactivityLocation] = useState<any>({});
  const [currentLocation, setcurrentLocation] = useState<any>({});
  const [isReached, setisReached] = useState<boolean>(false);
  const [openRemoteModal, setOpenRemoteModal] = useState<boolean>(false);
  const [manualClick, setManualClick] = useState<boolean>(false);
  const [isContributeOpen, setIsContributeOpen] = useState(false);
  const [viewContributions, setviewContributions] = useState(false);
  const success = useAppSelector(state => state.chatPanelReducer.success);
  const [calDialogOpen, setCalDialogOpen] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [childrens, setChildrens] = React.useState<any>([]);
  const [isVolunteerModalOpen, setIsVolunteerModalOpen] = useState(false);

  const [authModalOpen, setAuthModalOpen] = React.useState(false);
  const [currentComponent, setCurrentComponent] = React.useState(' ');
  const [openModal, setOpenModal] = useState(false);
  // const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const myCookieValue = Cookies.get('jhi-authenticationToken');
  const authenticationToken = myCookieValue;
  const isAuthenticated = !!authenticationToken;
  const userOwnedOrganizations = useAppSelector(state => state.organizationReducer.userOwnedOrganization);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedOrgId, setSelectedOrgId] = useState(null);

  let token;

  const [showOrgDialog, setShowOrgDialog] = useState(false);
  const handleOrgsDialog = value => {
    setShowOrgDialog(value);
  };

  const [currentActivityId, setCurrentActivityId] = useState(null);

  const getCurrentActivity = id => {
    setCurrentActivityId(id);
  };
  const clearCurrentActivity = () => {
    setCurrentActivityId(null);
  };

  useEffect(() => {
    console.log('Inside activityResponseParent', activityResponseParent);
    if (activityResponseParent?.recurringId === null) setactivityResponse(activityResponseParent);
  }, [activityResponseParent]);

  const handleOptionChange = event => {
    const selectedValue = event.target.value;

    if (parseInt(selectedValue, 10) === 1) {
      setSelectedOrgId(1);
      setSelectedOption('My Self');
    } else {
      const selectedOrganization = userOwnedOrganizations.find(organization => organization.id === parseInt(selectedValue, 10));

      if (selectedOrganization) {
        setSelectedOption(selectedOrganization.label);
        setSelectedOrgId(selectedOrganization.id);
      }
    }
  };

  const handleInstructionsModal = value => {
    setIsVolunteerModalOpen(value);
  };

  const handleCancel = () => {
    setShowOrgDialog(false);
    setSelectedOrgId(null);
    setSelectedOption('');
  };

  const changeOrgId = value => {
    setSelectedOrgId(value);
  };

  const handleVolunteerAs = async id => {
    if (isAuthenticated) {
      const res = await dispatch(fetchBanned({ userId: JSON.parse(localStorage.getItem('MyDetailsAccount'))?.id }));
      if (res?.payload) {
        toast.error(<>Action Restricted: You cannot perform this action as you are banned by the admin.</>);
        return;
      }
      const res1 = await dispatch(fetchBlocked({ userId: activityResponse?.createdBy }));
      if (res1?.payload) {
        toast.error(<>Action Restricted: You cannot volunteer for this activity.</>);
        return;
      }
      if (userOwnedOrganizations.length > 0) {
        if (selectedOption && currentActivityId === id) {
          openVolunteerModal();
        } else {
          handleOrgsDialog(true);
          getCurrentActivity(id);
        }
      } else {
        handleOrgsDialog(false);
        changeOrgId(1);
        getCurrentActivity(id);
        openVolunteerModal();
      }
    } else {
      setCurrentComponent('login');
      dispatch(toggleModalLogin());

      setAuthModalOpen(true);
    }
  };

  const authTokenFromStorage = (async () => {
    try {
      const authToken = await getAccessToken();
      if (authToken) {
        return authToken;
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error:', error);
      return null;
    }
  })();
  (async () => {
    token = await authTokenFromStorage;
  })();

  useEffect(() => {
    const allResourcesEmpty = activityResponse?.resources?.filter(item => item.filled > 0);
    allResourcesEmpty?.length === 0 ? setIsEmpty(true) : setIsEmpty(false);
  }, [activityResponse]);

  useEffect(() => {
    const latitude = activityResponse?.latitude;
    const longitude = activityResponse?.longitude;
    const activityLocation = {
      latitude: latitude,
      longitude: longitude,
    };
    setactivityLocation(activityLocation);
  }, [volunteerButtonClick, isSubmitClicked]);

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleAuthModalClose = () => {
    setAuthModalOpen(false);
  };

  const openVolunteerModal = () => {
    if (token !== null && token?.length) {
      setIsVolunteerModalOpen(true);
    } else {
      setCurrentComponent('login');
      dispatch(toggleModalLogin());

      setAuthModalOpen(true);
    }
  };

  const errors = err => {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  };

  // check if user's location is within 100 meters of event location
  const isWithin100Meters = (userLocation, eventLocation) => {
    const distance = calculateDistance(userLocation, eventLocation);
    return distance < 100;
  };

  // Check if today is the activity start day
  const isCurrentDateEqualToEventDate = startDate => {
    const currentDate = new Date();
    const eventDate = new Date(startDate);

    currentDate.setHours(0, 0, 0, 0);
    eventDate.setHours(0, 0, 0, 0);

    return isEqual(currentDate, eventDate);
  };

  // check if current time is within 30 minutes of start time of the activity
  const isCurrentTimeWithin30MinutesBeforeActivityStart = activityStartTime => {
    const currentDateTime = new Date();
    const eventDateTime = parseISO(activityStartTime);
    // Check if the current time is before the event time
    if (isBefore(currentDateTime, eventDateTime)) {
      // Calculate the time difference in minutes
      const timeDifferenceMinutes = differenceInMinutes(eventDateTime, currentDateTime);
      // Check if the difference is less than or equal to 30 minutes
      return timeDifferenceMinutes <= 30;
    }
    return false; // Event time has already passed
  };

  const isAnnounceArrivalButtonVisible = (activityDate, activityTime) => {
    if (isCurrentDateEqualToEventDate(activityDate) && isCurrentTimeWithin30MinutesBeforeActivityStart(activityTime)) {
      return true;
    } else {
      return false;
    }
  };

  const handleAnnounceArrival = async () => {
    let currentLocation;
    try {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(
          async function (position) {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            currentLocation = {
              latitude: latitude,
              longitude: longitude,
            };
            setcurrentLocation(currentLocation);
            // announce arrival
            const response = await dispatch(
              announceArrival({
                activityId: activityResponse?.id,
                latitude: currentLocation.latitude,
                longitude: currentLocation.longitude,
              })
            );
            if (response.meta.requestStatus == 'fulfilled') {
              toast.success(response.payload, { id: 'announce-arrival-success' });
              setisReached(true);
            } else {
              toast.error(response.payload, { id: 'announce-arrival-error' });
            }
          },
          function (error) {
            if (error.code === error.PERMISSION_DENIED) {
              alert('Location access denied. Please enable location services.');
            } else {
              alert('Error getting location: ' + error.message);
            }
          }
        );
      } else {
        alert('Geolocation is not supported in this browser.');
      }
    } catch (error) {
      toast.error('something went wrong!!', { id: 'announce-arrival-something-wrong' });
      console.error('API error:', error);
    }
  };

  const handleRemoteActivityClose = () => {
    setOpenRemoteModal(false);
  };
  const handleRemote = async () => {
    const body = {
      activityId: activityResponse?.id,
    };
    const response = await axios.post<any>(`/api/activity/announce-remote-arrival`, body);
  };
  const joinAndProceed = async () => {
    setIsButtonClicked(true);
    try {
      let response;
      if (selectedOrgId === null || selectedOrgId === 1) {
        response = await dispatch(participateInActivityCard({ activityIds: [activityResponse?.id] }));
      } else {
        response = await dispatch(participateInActivityCard({ activityIds: [activityResponse?.id], organizationId: selectedOrgId }));
      }
      if (response.meta.requestStatus == 'fulfilled') {
        setIsButtonClicked(false);
        setIsVolunteerModalOpen(false);
        setOpenModal(true);
        setManualClick(true);
        fetchData();
        // dispatch(getAllEvents);
        // if (localStorage.getItem('getChatData')) {
        //   let payload = {
        //     eventId: 0,
        //     eventType: '',
        //   };
        //   const keysJSON = await localStorage.getItem('getChatData');
        //   const keys = JSON.parse(keysJSON);
        //   payload = {
        //     eventId: keys.id,
        //     eventType: keys.eventType?.toUpperCase(),
        //   };
        //   dispatch(getAllConversationsById(payload));
        onVolunteerButtonClick(!volunteerButtonClick);
        // }

        //  fetchActivitiesRecurringInfo(activityResponse?.id);
        //  setCurrentSelecetd(activityResponse);
      } else {
        setIsVolunteerModalOpen(false);
      }
    } catch (error) {
      console.error('Error joining the activity:', error);
    }
  };

  const handleResource = () => {
    setIsContributeOpen(true);
  };

  const handleResourceClose = () => {
    setIsContributeOpen(false);
    fetchActivitiesRecurringInfo(activityResponse?.id);
  };

  const handleContributionsViewModal = () => {
    setviewContributions(true);
  };

  const handleContributionsViewModalClose = () => {
    setviewContributions(false);
  };
  const toolTipText = !isAuthenticated
    ? null
    : isAuthenticated && !activityResponse?.participant
    ? 'Be a volunteer to access the link'
    : null;
  const activityPostingRules = JSON.parse(localStorage.getItem('postingRules'));

  const VolunteerModal = (
    <Grid>
      {activityResponse && (
        <Grid>
          <Dialog
            open={isVolunteerModalOpen && activityResponse.id === currentActivityId}
            onClose={() => {
              setIsVolunteerModalOpen(false);
              handleCancel();
            }}
            PaperProps={{
              sx: {
                borderRadius: '15px',

                padding: '2%',
              },
            }}
          >
            <Stack direction="row" alignItems={'center'} justifyContent={'flex-end'}>
              <CloseIcon
                onClick={() => {
                  setIsVolunteerModalOpen(false);
                  handleCancel();
                }}
                sx={{ cursor: 'pointer' }}
              />
            </Stack>

            <DialogContent sx={{ height: 'auto', overflowY: 'hidden' }}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                {activityPostingRules && activityPostingRules?.length > 0 ? (
                  <Grid>
                    <Typography sx={{ fontSize: '14px/22px', color: isDarkMode ? '#FFCE00' : '#674BFF', marginBottom: '10px' }}>
                      Instructions for volunteers
                    </Typography>
                    <Paper
                      elevation={3}
                      sx={{
                        backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16))',
                        height: '150px',
                        width: '100%',
                        overflowX: 'hidden',
                        boxShadow: 'none',
                        overflowY: activityPostingRules && activityPostingRules?.length > 2 ? 'scroll' : 'hidden',
                        scrollbarWidth: 'thin',
                        '&::-webkit-scrollbar': {
                          display: 'block',
                        },
                        '&::-webkit-scrollbar-track': {
                          backgroundColor: 'primary.light',
                        },
                        '&::-webkit-scrollbar-thumb': {
                          backgroundColor: '#AAA',
                          borderRadius: '16px',
                        },
                      }}
                    >
                      {activityPostingRules?.length
                        ? activityPostingRules?.map(item => (
                            <Stack key={item.id} direction="row" spacing={1} alignItems="flex-start" justifyContent={'flex-start'}>
                              <Stack p={0.7}>
                                <Box
                                  sx={{
                                    width: '9px',
                                    height: '9px',
                                    borderRadius: '50%',
                                    backgroundColor: '#775FFF',
                                  }}
                                />
                              </Stack>

                              <Stack direction="row" sx={{ color: theme.textColor.paragraph, gap: 0.2, px: { xs: 0 } }}>
                                <Typography sx={{ fontSize: theme.infoText.regular.fontSize }}>{item.description}</Typography>
                              </Stack>
                            </Stack>
                          ))
                        : null}
                    </Paper>
                  </Grid>
                ) : null}
              </div>
              <div className="mt-2" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                {activityResponse?.instructions && activityResponse?.instructions?.length > 0 ? (
                  <Grid>
                    <Typography sx={{ fontSize: '14px/22px', color: isDarkMode ? '#FFCE00' : '#674BFF', marginBottom: '10px' }}>
                      Instructions by organizers
                    </Typography>

                    <Paper
                      elevation={3}
                      sx={{
                        backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16))',
                        p: 2,
                        height: '141px',
                        width: '100%',
                        overflowX: 'hidden',
                        boxShadow: 'none',
                        overflowY: activityResponse?.instructions && activityResponse?.instructions?.length > 2 ? 'scroll' : 'hidden',
                        scrollbarWidth: 'none',
                        '&::-webkit-scrollbar': {
                          display: 'none',
                        },
                        '&::-webkit-scrollbar-track': {
                          backgroundColor: 'primary.light',
                        },
                        '&::-webkit-scrollbar-thumb': {
                          backgroundColor: '#AAA',
                          borderRadius: '16px',
                        },
                      }}
                    >
                      {activityResponse?.instructions?.length
                        ? activityResponse?.instructions?.map(item => (
                            <Stack key={item.id} direction="row" spacing={1} alignItems="flex-start" justifyContent={'flex-start'}>
                              <Stack p={0.7}>
                                <Box
                                  sx={{
                                    width: '9px',
                                    height: '9px',
                                    borderRadius: '50%',
                                    backgroundColor: '#775FFF',
                                  }}
                                />
                              </Stack>

                              <Stack direction="row" sx={{ color: theme.textColor.paragraph, gap: 0.2, px: { xs: 0 } }}>
                                <Typography sx={{ fontSize: theme.infoText.regular.fontSize }}>{item.instruction}</Typography>
                              </Stack>
                            </Stack>
                          ))
                        : null}
                    </Paper>
                  </Grid>
                ) : null}
              </div>
            </DialogContent>

            <DialogActions
              sx={{
                justifyContent: 'center',
                textAlign: 'center',
              }}
            >
              <Stack
                direction={'column'}
                gap={1}
                sx={{
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Button
                  onClick={() => joinAndProceed()}
                  sx={{
                    background: theme.palette.primary.main,
                    color: '#292D34',
                    borderRadius: '15px',
                    width: '159px',
                    height: '40px',
                    '&:hover': {
                      background: theme.palette.primary.main,
                      color: '#292D34',
                      borderRadius: '15px',
                      width: '159px',
                      height: '40px',
                    },
                  }}
                  disabled={isButtonClicked}
                >
                  Join & proceed
                </Button>

                <Typography sx={{ fontSize: '14px', color: theme.textColor.heading }}>
                  By joining this event you accept the rules/instructions of this event.
                </Typography>
              </Stack>
            </DialogActions>
          </Dialog>
        </Grid>
      )}
    </Grid>
  );

  useEffect(() => {
    if (childrens && activityResponseParent?.recurringId) {
      console.log('calling childrens', childrens);

      const now = new Date().getTime();

      const filteredData = childrens.filter(item => !item.parent);
      const sortedData = filteredData.sort((a, b) => {
        const timeA = new Date(a.startDate).getTime();
        const timeB = new Date(b.startDate).getTime();

        const diffA = Math.abs(timeA - now);
        const diffB = Math.abs(timeB - now);

        return diffA - diffB;
      });

      console.log('Sorted data based on date closeness:', sortedData);

      if (sortedData.length > 0) {
        const selectedId = sortedData[0]?.id;
        fetchActivitiesRecurringInfo(selectedId);
      }
    }
  }, [childrens, activityResponseParent?.recurringId]);

  // console.log('checkmine', activityResponse);
  const fetchActivitiesRecurringInfo = async id => {
    let response;
    if (isAuthenticated) {
      response = await dispatch(fetchActivityDetails(id));
    } else {
      response = await dispatch(fetchActivityDetailsUnauthorized(id));
    }
    if (response.meta.requestStatus == 'fulfilled') {
      setactivityResponse(response?.payload);
    } else {
      // toast.error('No activity details found.', { id: 'activity-fetch-error' });
    }
  };
  const handleCalender = () => {
    setManualClick(false);
    setCalDialogOpen(true);
  };
  const [currentSelected, setCurrentSelecetd] = React.useState<number>(0);
  const handleCloseModal = filteredDataId => {
    setCalDialogOpen(false);
    fetchActivitiesRecurringInfo(filteredDataId);
    setCurrentSelecetd(filteredDataId);
  };
  const fetchData = async () => {
    if (activityResponseParent?.recurringId) {
      console.log(' i am entering undefined');
      try {
        const response = await dispatch(fetchRecurringDetails(Number(activityResponseParent?.recurringId)));
        // Access the response data here()
        setChildrens(response?.payload);
      } catch (error) {
        console.error('Recurring failed:', error);
      }
    }
  };
  useEffect(() => {
    if (activityResponseParent?.recurringId) fetchData();
  }, [activityResponseParent?.recurringId]);

  const [confirmLostPet, setConfirmLostPet] = React.useState<boolean>(false);
  const [lostDone, setLostDone] = useState<any>(false);
  const markAsDone = async (id: string) => {
    try {
      const response = await axios.post<any>(`/api/activities/${id}/complete`);
      if (response) {
        setLostDone(true);
      }
    } catch (error) {
      console.log('Error fetching goal:', error);
    }
  };
  const handleConfirmClick = () => {
    markAsDone(activityResponse?.id);
    setConfirmLostPet(false);
  };

  const modalActionLostButtons = [
    { label: 'Yes', onClick: handleConfirmClick },
    { label: 'No', onClick: () => setConfirmLostPet(false) },
  ];

  return (
    <div>
      <RadioDialog
        selectedOrgId={selectedOrgId}
        open={showOrgDialog}
        handleShow={handleOrgsDialog}
        handleOptionChange={handleOptionChange}
        handleCancel={handleCancel}
        handleInstructionsModal={handleInstructionsModal}
      />

      {!activityResponse && <Skeleton variant="rectangular" width={50} height={300} />}
      {activityResponse && Object.keys(activityResponse).length > 0 && (
        <Grid container padding={0} gap={0}>
          {VolunteerModal}
          <Grid item container>
            <Grid padding={1} sx={{ width: '100%', marginRight: '6px' }}>
              {activityResponseParent?.recurringId != null && (
                <div>
                  <CalendarMonthIcon onClick={handleCalender} sx={{ height: '22px', width: '25px', color: '#674BFF', cursor: 'pointer' }} />
                  <span style={{ fontSize: '14px', color: isDarkMode ? '#BFBFBF' : '#676E7B' }}>
                    {convertDateToYear(activityResponse?.startDate)}
                  </span>
                </div>
              )}
              <Typography
                sx={{
                  color: theme.textColor.mainHeading,
                  fontSize: theme.p1.semiBold.fontSize,
                  fontWeight: theme.p1.semiBold.fontWeight,
                }}
              >
                Instructions
              </Typography>
              <Paper
                className="hide-scrol"
                elevation={3}
                sx={{
                  background: isDarkMode ? '#2C2C38' : '#FFFFFF',
                  borderRadius: '16px',
                  p: 2,
                  paddingLeft: '0px',
                  height: '105px',
                  width: '100%',
                  overflowX: 'hidden',
                  boxShadow: 'none',
                }}
              >
                {activityResponse?.instructions?.length ? (
                  activityResponse?.instructions?.map(item => (
                    <Stack key={item.id} direction="row" spacing={1} alignItems="flex-start" justifyContent={'flex-start'}>
                      <Stack p={0.7}>
                        <Box
                          sx={{
                            width: '9px',
                            height: '9px',
                            borderRadius: '50%',
                            backgroundColor: '#775FFF',
                          }}
                        />
                      </Stack>
                      <Stack direction="row" sx={{ color: theme.textColor.paragraph, gap: 0.2, px: { xs: 0, lg: 0 } }}>
                        <Typography sx={{ fontSize: theme.infoText.regular.fontSize }}>{item.instruction}</Typography>
                      </Stack>
                    </Stack>
                  ))
                ) : (
                  <Typography sx={{ textAlign: 'left' }}>No listed instructions</Typography>
                )}
              </Paper>
            </Grid>
          </Grid>
          <hr style={{ width: '100%', textAlign: 'left', marginLeft: '0' }}></hr>

          <Grid sx={{ width: '100%', marginRight: '6px' }}>
            <Grid padding={1}>
              <Typography
                sx={{
                  color: theme.textColor.mainHeading,
                  fontSize: theme.p1.semiBold.fontSize,
                  fontWeight: theme.p1.semiBold.fontWeight,
                }}
              >
                Resources needed
              </Typography>
              {!isEmpty && (
                <Typography
                  sx={{
                    color: theme.palette.secondary.light,
                    fontSize: theme.infoText.regular.fontSize,
                    fontWeight: theme.infoText.regular.fontWeight,
                    paddingBottom: '12px',
                  }}
                >
                  Please add your contributions that you are willing to bring
                </Typography>
              )}
              {!isEmpty && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Link
                    style={{ paddingBottom: '12px', fontSize: '14px', fontWeight: '600', color: '#674BFF', textDecoration: 'none' }}
                    onClick={() => handleContributionsViewModal()}
                    to={''}
                  >
                    View contributors
                  </Link>
                </div>
              )}

              {activityResponse?.resources?.length === 0 && <Typography sx={{ lineHeight: '3' }}>No listed resources</Typography>}
              <Paper
                className="hide-scrol "
                elevation={3}
                sx={{
                  background: isDarkMode ? '#2C2C38' : '#FFFFFF',
                  borderRadius: '16px',
                  p: 1,
                  height: '100px',
                  width: '100%',
                  overflowX: 'hidden',
                  overflowY: activityResponse?.resources?.length > 1 ? 'scroll' : 'hidden',
                  boxShadow: 'none',
                }}
              >
                <ol style={{ paddingLeft: '8px' }}>
                  {activityResponse?.resources?.length
                    ? activityResponse?.resources.map((resource, index) => (
                        <li key={index} className="pb-2">
                          <Typography
                            sx={{
                              color: theme.textColor.mainHeading,
                              fontSize: theme.p1.regular.fontSize,
                              fontWeight: theme.p1.regular.fontWeight,
                            }}
                          >
                            {resource.resources}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: theme.infoText.bold.fontSize,
                              fontWeight: theme.infoText.bold.fontWeight,
                              color: resource.filled >= resource.totalCount ? ' #00B158' : '#FF4B8E',
                            }}
                          >
                            {resource.filled >= resource.totalCount
                              ? `${resource.filled}/${resource.totalCount} Filled `
                              : `${resource.filled}/${resource.totalCount} Filled `}
                          </Typography>
                        </li>
                      ))
                    : null}
                </ol>
              </Paper>
            </Grid>
          </Grid>

          {activityResponse?.resources?.length ? (
            <Grid className="mt-3" item container justifyContent={'center'} alignItems={'center'} gap={2} marginBottom={2}>
              {activityResponse?.participant &&
                (activityResponse?.activityStatus === 'OPEN' || activityResponse?.activityStatus === 'RUNNING') && (
                  <Button
                    variant="outlined"
                    sx={{ borderRadius: '15px', fontSize: '14px', fontWeight: '600' }}
                    onClick={() => handleResource()}
                    disabled={activityResponse?.resources?.length === 0}
                  >
                    Contribute
                  </Button>
                )}
            </Grid>
          ) : null}

          {!activityResponse?.currentActivityVolunteer &&
            !manualClick &&
            activityResponse.activityStatus === 'OPEN' &&
            !activityResponse.makeThisPrivateActivity && (
              <Grid item container justifyContent={'center'} alignItems={'center'} className="mb-2">
                <Button
                  variant="contained"
                  style={{ textTransform: 'none' }}
                  sx={{ borderRadius: '15px', fontSize: '14px', fontWeight: '600' }}
                  onClick={() => handleVolunteerAs(activityResponse.id)}
                >
                  I want to volunteer
                </Button>
              </Grid>
            )}

          {!activityResponse?.currentActivityVolunteer &&
            !manualClick &&
            activityResponse.activityStatus === 'OPEN' &&
            activityResponse.makeThisPrivateActivity &&
            activityResponse.invited && (
              <Grid item container justifyContent={'center'} alignItems={'center'} className="mb-2">
                <Button
                  variant="contained"
                  style={{ textTransform: 'none' }}
                  sx={{ borderRadius: '15px', fontSize: '14px', fontWeight: '600' }}
                  onClick={() => handleVolunteerAs(activityResponse.id)}
                >
                  I want to volunteer
                </Button>
              </Grid>
            )}
          {activityResponse?.activityStatus === 'OPEN' &&
            activityResponse?.currentActivityVolunteer &&
            !activityResponse?.hasPermission && (
              <Grid item container justifyContent={'center'} alignItems={'center'} className="mb-2">
                <Button
                  variant="text"
                  sx={{
                    fontSize: '14px',
                    borderRadius: '20px',
                    height: '28px',
                    lineHeight: '12px',
                    cursor: 'auto',
                    color: isDarkMode ? '#FFCE00' : '#674BFF',
                    '&:hover': {
                      color: 'primary.light',
                    },
                  }}
                >
                  Thanks for volunteering
                </Button>
              </Grid>
            )}
          {activityResponse?.activityStatus === 'RUNNING' && activityResponse?.category?.id !== 19 && (
            <Grid item container justifyContent={'center'} alignItems={'center'} className="mb-2">
              <Button
                variant="text"
                sx={{
                  fontSize: '14px',
                  borderRadius: '20px',
                  height: '28px',
                  lineHeight: '12px',
                  cursor: 'auto',
                  color: 'primary.light',
                  '&:hover': {
                    color: 'primary.light',
                  },
                }}
              >
                Started
              </Button>
            </Grid>
          )}

          {activityResponse?.activityStatus === 'RUNNING' &&
            !activityResponse?.currentActivityVolunteer &&
            !activityResponse?.hasPermission &&
            activityResponse?.category?.id === 19 && (
              <Grid item container justifyContent={'center'} alignItems={'center'} className="mb-2">
                <Button
                  variant="contained"
                  style={{ textTransform: 'none' }}
                  sx={{ borderRadius: '15px', fontSize: '14px', fontWeight: '600' }}
                  onClick={() => handleVolunteerAs(activityResponse.id)}
                >
                  I want to volunteer
                </Button>
              </Grid>
            )}
          {activityResponse?.activityStatus === 'RUNNING' &&
            activityResponse?.currentActivityVolunteer &&
            !activityResponse?.hasPermission &&
            activityResponse?.category?.id === 19 && (
              <Grid item container justifyContent={'center'} alignItems={'center'} className="mb-2">
                <Button
                  variant="text"
                  sx={{
                    fontSize: '14px',
                    borderRadius: '20px',
                    height: '28px',
                    lineHeight: '12px',
                    cursor: 'auto',
                    color: 'primary.light',
                    '&:hover': {
                      color: 'primary.light',
                    },
                  }}
                >
                  Thanks for volunteering
                </Button>
              </Grid>
            )}
          {activityResponse?.activityStatus === 'RUNNING' &&
            activityResponse?.category?.id === 19 &&
            !lostDone &&
            activityResponse?.hasPermission && (
              <Grid item container justifyContent={'center'} alignItems={'center'} className="mb-2">
                <Button
                  variant="contained"
                  sx={{
                    fontSize: '14px',
                    borderRadius: '20px',
                    height: '28px',
                    lineHeight: '12px',
                    '&:hover': {
                      backgroundColor: 'primary.main',
                      color: 'common.black',
                    },
                    width: 'fit-content',
                  }}
                  onClick={() => {
                    setConfirmLostPet(true);
                  }}
                >
                  Mark as done
                </Button>
              </Grid>
            )}

          {activityResponse?.activityStatus === 'RUNNING' &&
            activityResponse?.category?.id === 19 &&
            lostDone &&
            activityResponse?.hasPermission && (
              <Grid item container justifyContent={'center'} alignItems={'center'} className="mb-2">
                <Button
                  variant="text"
                  sx={{
                    fontSize: '14px',
                    borderRadius: '20px',
                    height: '28px',
                    lineHeight: '12px',
                    cursor: 'auto',
                    color: isDarkMode ? '#FFCE00' : '#674BFF',
                    '&:hover': {
                      color: isDarkMode ? '#FFCE00' : '#674BFF',
                    },
                  }}
                >
                  Completed
                </Button>
              </Grid>
            )}
          {activityResponse?.activityStatus === 'ENDED' && (
            <Grid item container justifyContent={'center'} alignItems={'center'} className="mb-2">
              <Button
                variant="text"
                sx={{
                  fontSize: '14px',
                  borderRadius: '20px',
                  height: '28px',
                  lineHeight: '12px',
                  cursor: 'auto',
                  color: 'primary.light',
                  '&:hover': {
                    color: 'primary.light',
                  },
                }}
              >
                Pending feedback
              </Button>
            </Grid>
          )}
          {activityResponse?.activityStatus === 'COMPLETED' && (
            <Grid item container justifyContent={'center'} alignItems={'center'} className="mb-2">
              <Button
                variant="text"
                style={{
                  cursor: 'default',
                }}
                sx={{
                  fontSize: '14px',
                  borderRadius: '20px',
                  height: '28px',
                  lineHeight: '12px',
                  color: 'primary.light',
                  '&:hover': {
                    color: 'primary.light',
                  },
                }}
              >
                Completed
              </Button>
            </Grid>
          )}

          {selectedOrgId && isVolunteerModalOpen && VolunteerModal}

          <ActivityContribute
            open={isContributeOpen}
            handleClose={handleResourceClose}
            handleData={activityResponse}
            onPostClick={() => onIsSubmitClicked(true)}
          />

          <AllContributionsListModal open={viewContributions} handleClose={handleContributionsViewModalClose} activity={activityResponse} />

          {/* login modal */}
          <AuthorisationModal
            currentComponent={currentComponent}
            fullScreen={fullScreen}
            authModalOpen={authModalOpen}
            onClose={handleAuthModalClose}
            handleAuthModalClose={handleAuthModalClose}
            setCurrentComponent={setCurrentComponent}
          />
          <JoiningSuccessModal
            handleClose={handleModalClose}
            fullScreen={fullScreen}
            open={openModal}
            url={'chat url'}
            title="Thank you for volunteering."
          />
          <Dialog
            fullScreen={fullScreen}
            open={openRemoteModal}
            onClose={handleRemoteActivityClose}
            aria-labelledby="remote-activity-modal"
            PaperProps={{
              sx: {
                borderRadius: { xs: 0, sm: '14px' },
                boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
                width: { lg: 400 },
              },
            }}
          >
            <Stack direction="column" sx={{ p: 2 }} spacing={3} minWidth={400} minHeight={200}>
              <Stack direction="row" alignItems={'center'} justifyContent={'flex-end'}>
                <CloseIcon onClick={handleRemoteActivityClose} sx={{ cursor: 'pointer' }} />
              </Stack>
              <Typography variant="h4" textAlign={'center'}>
                Meeting Link
              </Typography>
              <Button
                variant="outlined"
                sx={{ borderColor: 'primary.light', py: 1, borderRadius: '20px', '&:hover': { borderColor: 'primary.light' } }}
              >
                <Link to={activityResponse?.onlineUrl} target="_blank" rel="noopener noreferrer" onClick={handleRemote}>
                  <Stack direction="column" alignItems={'center'}>
                    <Typography
                      sx={{
                        wordBreak: 'break-word',
                        font: 'normal normal normal 14px/23px Manrope',
                        color: isDarkMode ? theme.palette.secondary.contrastText : '#676E7B',
                      }}
                    >
                      {activityResponse?.onlineUrl}
                    </Typography>
                  </Stack>
                </Link>
              </Button>
            </Stack>
          </Dialog>
        </Grid>
      )}

      <GenericModal
        handleClose={() => setConfirmLostPet(false)}
        fullScreen={fullScreen}
        open={confirmLostPet}
        title="Are you sure you want to mark this activity as done?"
        buttons={modalActionLostButtons}
      />
      <RecurringRangeModal open={calDialogOpen} onClose={handleCloseModal} handleData={childrens} />
    </div>
  );
};
export default ActivityChatSideBar;
