// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carousel .slide .legend {
  background: rgba(103, 110, 123, 0.4) !important;
  opacity: 1 !important;
  left: 45% !important;
  width: 100% !important;
  border-radius: 0px !important;
}

.av-size {
  height: 20px !important;
  width: 20px !important;
  font-size: 12px !important;
}

.css-lrmc1p-MuiAvatar-root-MuiAvatarGroup-avatar {
  height: 20px !important;
  width: 20px !important;
  font-size: 12px !important;
  display: none !important;
}

.css-11hi88g-MuiAvatarGroup-root .MuiAvatar-root {
  border: none !important;
  box-sizing: content-box;
  margin-left: -8px;
  height: 20px !important;
  width: 20px !important;
  font-size: 12px !important;
}

.MuiAvatar-root.MuiAvatar-circular.MuiAvatar-colorDefault.MuiAvatarGroup-avatar.css-o5qrff-MuiAvatar-root-MuiAvatarGroup-avatar {
  display: none !important;
}

@media only screen and (max-width: 365px) {
  .res-mob {
    right: 10px;
  }
}
@media only screen and (max-width: 450px) {
  .bio-phone {
    width: 186px !important;
    text-align: justify !important;
  }
  .postby-font {
    font-size: 15px !important;
  }
  .mob-date {
    flex-direction: row !important;
    margin-bottom: 4.2px !important;
  }
  .mob-loc {
    flex-direction: column !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/components/ui/activity-recurring-section/recurring-details-card/recurring-details-card.scss"],"names":[],"mappings":"AAAA;EACE,+CAAA;EACA,qBAAA;EACA,oBAAA;EACA,sBAAA;EACA,6BAAA;AACF;;AACA;EACE,uBAAA;EACA,sBAAA;EACA,0BAAA;AAEF;;AAAA;EACE,uBAAA;EACA,sBAAA;EACA,0BAAA;EACA,wBAAA;AAGF;;AADA;EACE,uBAAA;EACA,uBAAA;EACA,iBAAA;EACA,uBAAA;EACA,sBAAA;EACA,0BAAA;AAIF;;AADA;EACE,wBAAA;AAIF;;AAFA;EACE;IACE,WAAA;EAKF;AACF;AAHA;EACE;IACE,uBAAA;IACA,8BAAA;EAKF;EAHA;IACE,0BAAA;EAKF;EAHA;IACE,8BAAA;IACA,+BAAA;EAKF;EAHA;IACE,iCAAA;EAKF;AACF","sourcesContent":[".carousel .slide .legend {\n  background: rgba(103, 110, 123, 0.4) !important;\n  opacity: 1 !important;\n  left: 45% !important;\n  width: 100% !important;\n  border-radius: 0px !important;\n}\n.av-size {\n  height: 20px !important;\n  width: 20px !important;\n  font-size: 12px !important ;\n}\n.css-lrmc1p-MuiAvatar-root-MuiAvatarGroup-avatar {\n  height: 20px !important;\n  width: 20px !important;\n  font-size: 12px !important ;\n  display: none !important;\n}\n.css-11hi88g-MuiAvatarGroup-root .MuiAvatar-root {\n  border: none !important;\n  box-sizing: content-box;\n  margin-left: -8px;\n  height: 20px !important;\n  width: 20px !important;\n  font-size: 12px !important ;\n  // display: none !important;\n}\n.MuiAvatar-root.MuiAvatar-circular.MuiAvatar-colorDefault.MuiAvatarGroup-avatar.css-o5qrff-MuiAvatar-root-MuiAvatarGroup-avatar {\n  display: none !important;\n}\n@media only screen and (max-width: 365px) {\n  .res-mob {\n    right: 10px;\n  }\n}\n@media only screen and (max-width: 450px) {\n  .bio-phone {\n    width: 186px !important;\n    text-align: justify !important;\n  }\n  .postby-font {\n    font-size: 15px !important;\n  }\n  .mob-date {\n    flex-direction: row !important;\n    margin-bottom: 4.2px !important;\n  }\n  .mob-loc {\n    flex-direction: column !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
