import React, { useEffect, useState } from 'react';

import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  Tooltip,
  Button,
  Stack,
  useTheme,
  Typography,
  ListItem,
  ListItemText,
  useMediaQuery,
  Dialog,
  Box,
  Grid,
  DialogContent,
  DialogActions,
  Paper,
  AvatarGroup,
} from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import SquareChatIcon from 'app/components/icons/header-icons/square-chat-icon';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import {
  convertDateToDay,
  convertDateToYear,
  generateGMapsLinkCords,
  generateGMapsLinkPlace,
  getFormattedTime,
  getInitials,
  shareOnSocialMedia,
} from 'app/utils/common-utils/common-utils';

import UpVoteOutlined from 'app/components/icons/activity-card/up-vote-outlined';
import Popover from '@mui/material/Popover';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import GenericModal from '../../modals/generic-modal';
import { Link, useNavigate } from 'react-router-dom';
import ShareIcon from 'app/components/icons/activity-card/share-icon';
import './activity-card.scss';
import RemoteEventIcon from 'app/components/icons/activity-card/remote-event';
import CloseIcon from '@mui/icons-material/Close';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { fetchBookmarkedActivity } from 'app/shared/reducers/bookmark-activity';
import { fetchLikes } from 'app/shared/reducers/activity-like';
import { participateInActivityCard } from 'app/shared/reducers/volunteer';
import JoiningSuccessModal from '../../modals/joining-success-modal';
import UpVoteFilled from 'app/components/icons/activity-card/up-vote-filled';
import LeaveActivityDialog from 'app/components/ui/activity-details-section/activity-resource/activity-leave';
import ReportActivityDialog from 'app/components/ui/activity-details-section/activity-resource/activity-report';
import { appConstants } from 'app/utils/data/constants/constants';

import ActivityRatingModal from '../../modals/activity-rating-modal/activity-rating-modal';
import AuthorisationModal from '../../authorization-modal/authorization-modal';
import BlueTwitterIcon from 'app/components/icons/login-page-icons/blue-twitter-icon';
import GrayTwitterIcon from 'app/components/icons/login-page-icons/gray-twitter-icon';
import { getPhoneNumber, toggleModalLogin } from 'app/shared/reducers/authentication';
import { fetchActivityShareToken } from 'app/shared/reducers/activity-home';
import toast from 'react-hot-toast';
import FacebookIcon from 'app/components/icons/login-page-icons/facebook-icon';
import LinkedInIcon from 'app/components/icons/login-page-icons/linkedin-icon';
import axios from 'axios';
import FeatureDialog from '../../alert-dialog/feature-dialog';
import { fetchBanned, fetchBlocked, fetchProfileDetails } from 'app/shared/reducers/profile';
import Moment from 'react-moment';
import { updatePrevItemId } from 'app/shared/reducers/activity-filter';
import PhoneVerification from 'app/components/ui/phone-verification/phone-verification';
import Lightbox from 'yet-another-react-lightbox';
import Video from 'yet-another-react-lightbox/plugins/video';
import 'yet-another-react-lightbox/styles.css';
import { GallerySlide } from 'app/components/ui/activity-details-section/activity-details-card/activity-details-card';
import ImageGrid from 'app/components/ui/grid-layout/ImageGrid';
import InviteMoreModal from '../../modals/invite-more/invite-more';
import ReactGA from 'react-ga4';
import VisibilityIcon from '@mui/icons-material/Visibility';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';

interface SingleActivityCardProps {
  eventGallery: { link: string; type: string }[];
  handleOrgsDialog?: (value?: boolean) => void;
  handleDeleteClick: (id: string) => void;
  getCurrentActivity?: (id: number) => void;
  onBookmarkSuccess: () => void;
  onLikeSuccess: () => void;
  activity: any;
  selectedOption?: string;
  selectedOrgId?: number;
  showOrgDialog?: boolean;
  fromProfile?: boolean;
  currentActivityId?: number;
  clearCurrentActivity?: () => void;
  handleCancel?: () => void;
  isVolunteerModalOpen?: boolean;
  handleInstructionsModal?: (value: boolean) => void;
  changeOrgId?: (value: number) => void;
  commonParams?: any;
}

const SingleActivityCard = ({
  handleDeleteClick,
  onBookmarkSuccess,
  onLikeSuccess,
  activity,
  handleOrgsDialog,
  selectedOption,
  selectedOrgId,
  showOrgDialog,
  getCurrentActivity,
  currentActivityId,
  changeOrgId,
  handleCancel,
  fromProfile,
  isVolunteerModalOpen,
  commonParams,
  handleInstructionsModal,
}: SingleActivityCardProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const { filter, eventType } = useAppSelector(state => state.filters.RequestBody);
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down(400));
  const isMdScreen = useMediaQuery(theme.breakpoints.down(992));
  const isNewScreen = useMediaQuery(theme.breakpoints.down(983));
  const [places, setPlaces] = useState<{ place: string; address: string }>({ place: '', address: '' });
  const [openRemoteModal, setOpenRemoteModal] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [socialAnchorEl, setSocialAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [confirmDelete, setConfirmDelete] = React.useState<boolean>(false);
  const [confirmLostPet, setConfirmLostPet] = React.useState<boolean>(false);
  const [isBookmarked, setIsBookmarked] = useState<boolean>(false);
  const [isLiked, setIsLiked] = useState<boolean>(false);
  const [likeCountNumber, setLikeCountNumber] = useState<number>(0);
  const [authModalOpen, setAuthModalOpen] = React.useState(false);
  const [currentComponent, setCurrentComponent] = React.useState(' ');
  const [openModal, setOpenModal] = useState(false);
  const [leaveDialogOpen, setLeaveDialogOpen] = useState(false);
  const [reportDialogOpen, setReportDialogOpen] = useState(false);
  const [isRatingModalOpen, setIsRatingModalOpen] = useState(false);
  const showAuthModal = useAppSelector(state => state.authentication.showModalLogin);
  const userOwnedOrganizations = useAppSelector(state => state.organizationReducer.userOwnedOrganization);
  const myProfileId = useAppSelector(state => state.authentication.account.id);
  const [isVisible, setisVisible] = useState(false);
  const handleRatingModalClose = () => {
    setIsRatingModalOpen(false);
  };
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [isButtonVolunteeer, setIsButtonVolunteeer] = useState(false);

  const openVolunteerModal = () => {
    if (isAuthenticated) {
      setIsButtonVolunteeer(true);
      handleInstructionsModal(true);
    } else {
      dispatch(toggleModalLogin());
      setCurrentComponent('login');

      setAuthModalOpen(true);
    }
  };

  // const openVolunteerModal = () => {
  //   setIsButtonVolunteeer(true);
  //   showInstructionsModal()
  // };

  const openRatingModal = () => {
    if (isAuthenticated) {
      setIsRatingModalOpen(true);
    } else {
      dispatch(toggleModalLogin());
      setCurrentComponent('login');
      setAuthModalOpen(true);
    }
  };
  const handleAuthModalClose = () => {
    setAuthModalOpen(false);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const [openInviteModal, setOpenInviteModal] = useState(false);

  const [webUrl, setwebUrl] = useState(activity?.eventURL ? activity?.eventURL : '');

  const handleInviteModalClose = () => {
    setOpenInviteModal(false);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSocialShareClose = () => {
    setSocialAnchorEl(null);
  };

  const handleRemoteActivityClose = () => {
    setOpenRemoteModal(false);
  };
  const handleRemote = async () => {
    const body = {
      activityId: activity?.id,
    };
    const response = await axios.post<any>(`/api/activity/announce-remote-arrival`, body);
  };
  const open = Boolean(anchorEl);
  const socialShare = Boolean(socialAnchorEl);
  const menuItemId = open ? 'simple-popover' : undefined;
  const socialShareId = socialShare ? 'simple-popover-social' : undefined;
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleButtonClick = activity => {
    localStorage.setItem('dynamicDescription', activity.caption);
    localStorage.setItem('dynamicTitle', activity.title);
    localStorage.setItem('dynamicImage', activity?.eventGallery[0]?.link);
    localStorage.setItem('dynamicUrl', activity?.eventURL);
    if (localStorage.getItem('dynamicImage')) {
      const encodedTitle = activity.title.replace(/\?/g, '').replace(/ /g, '-').toLowerCase();
      ReactGA.event({
        category: 'Activity',
        action: 'navigate_activity',
        label: activity.title,
      });
      console.log('activityId1', encodedTitle);
      navigate(`/activity/${encodedTitle}-${activity?.id}`);
      dispatch(updatePrevItemId(activity?.id));
    }
  };

  const handleImageError = event => {
    event.target.src = 'content/images/no-img.jpg';
  };

  const handleYesClick = () => {
    handleDeleteClick(activity?.id);

    handleClose();
  };

  const isDarkMode = theme.palette.mode === 'dark';

  const modalActionButtons = [
    { label: 'Yes', onClick: handleYesClick },
    { label: 'No', onClick: () => setConfirmDelete(false) },
  ];
  const [lostDone, setLostDone] = useState<any>(false);
  const markAsDone = async (id: string) => {
    try {
      const response = await axios.post<any>(`/api/activities/${id}/complete`);
      if (response) {
        setLostDone(true);
      }
    } catch (error) {
      console.log('Error fetching goal:', error);
    }
  };
  const handleConfirmClick = () => {
    markAsDone(activity?.id);
    setConfirmLostPet(false);
  };

  const modalActionLostButtons = [
    { label: 'Yes', onClick: handleConfirmClick },
    { label: 'No', onClick: () => setConfirmLostPet(false) },
  ];

  const postedByStyle = {
    font: 'normal normal 600 16px/22px Manrope',
    color: isDarkMode ? theme.palette.secondary.contrastText : 'secondary.main',
  };

  const location = {
    font: 'normal normal normal 14px/23px Manrope',
    color: isDarkMode ? theme.palette.secondary.contrastText : '#676E7B',
  };

  const locationXs = {
    font: 'normal normal normal 14px/21px Manrope',
    color: isDarkMode ? theme.palette.secondary.contrastText : '#676E7B',
  };

  const handleRemoteActivityButton = () => {
    if (isAuthenticated) {
      if (
        activity?.online &&
        activity?.participant &&
        (Math.abs(new Date(activity.startTime).getTime() - new Date().getTime()) <= 30 * 60 * 1000 || activity?.hasPermission)
      ) {
        setOpenRemoteModal(s => !s);
      }
    } else {
      dispatch(toggleModalLogin());
      setCurrentComponent('login');
      setAuthModalOpen(true);
    }
  };

  const handleShareButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setSocialAnchorEl(event.currentTarget);
  };

  const handleLikeClick = async () => {
    if (!isAuthenticated) {
      dispatch(toggleModalLogin());

      setCurrentComponent('login');
      setAuthModalOpen(true);

      return;
    }

    if (isAuthenticated) {
      const res = await dispatch(fetchBanned({ userId: JSON.parse(localStorage.getItem('MyDetailsAccount'))?.id }));
      if (res?.payload) {
        toast.error(<>Action Restricted: You cannot perform this action as you are banned by the admin.</>);
        return;
      }
    }

    setIsLiked(s => !s);

    if (isLiked) {
      setLikeCountNumber(likeCountNumber - 1);
    } else {
      setLikeCountNumber(likeCountNumber + 1);
    }

    try {
      const actionResult = await dispatch(fetchLikes(Number(activity?.id)));
      if (fetchLikes.fulfilled.match(actionResult)) {
        onLikeSuccess();
        // dispatch(filterActivities({ filter, eventType, page: 0 }));
      }
    } catch (error) {
      console.error('Liking failed:', error);

      setIsLiked(s => !s);
    }
  };

  const sentParameters = async data => {
    if (!isAuthenticated) {
      setCurrentComponent('login');
      dispatch(toggleModalLogin());

      setAuthModalOpen(true);
    } else {
      if (activity?.recurringId) {
        const { id, eventType } = data;
        let ID = activity?.recurringId;
        const storageData = { id: ID, eventType };
        if (localStorage.getItem('dataKey')) {
          localStorage.removeItem('dataKey');
        }
        localStorage.setItem('dataKey', JSON.stringify(storageData));
        navigate('/chat');
      } else {
        const { id, eventType } = data;
        const storageData = { id, eventType };
        if (localStorage.getItem('dataKey')) {
          localStorage.removeItem('dataKey');
        }
        localStorage.setItem('dataKey', JSON.stringify(storageData));
        navigate('/chat');
      }
    }
  };

  const handleBookmarkClick = async () => {
    if (!isAuthenticated) {
      setCurrentComponent('login');
      dispatch(toggleModalLogin());
      setAuthModalOpen(true);
      return;
    }

    setIsBookmarked(s => !s);

    try {
      const actionResult = await dispatch(fetchBookmarkedActivity(Number(activity?.id)));
      if (fetchBookmarkedActivity.fulfilled.match(actionResult)) {
        onBookmarkSuccess();
        // dispatch(filterActivities({ filter, eventType, page: 0 }));
      }
    } catch (error) {
      console.error('Bookmarking failed:', error);

      setIsBookmarked(s => !s);
    }
  };

  const handleLeave = () => {
    handleClose();

    setLeaveDialogOpen(true);
  };

  const handleLeaveClose = () => {
    setLeaveDialogOpen(false); // Close the dialog
    if (fromProfile) {
      dispatch(fetchProfileDetails({ ...commonParams, eventType: 'Bookmarked' }));
    }
  };

  const handleReportClose = () => {
    setReportDialogOpen(false); // Close the dialog
    if (fromProfile) {
      dispatch(fetchProfileDetails({ ...commonParams, eventType: 'Bookmarked' }));
    }
  };

  const handleReport = () => {
    handleClose();

    setReportDialogOpen(true);
  };

  const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const isBreakPoint = useMediaQuery(theme.breakpoints.down(970));

  //newwww
  const [volunteerAnchorEl, setVolunteerAnchorEl] = useState(null);

  const buttonText = isSmallDevice ? 'Volunteer' : 'I want to volunteer';
  const ratingButtonText = isBreakPoint ? 'Rate' : ' Rate this activity';

  const joinAndProceed = async () => {
    setIsButtonClicked(true);
    try {
      let response;
      if (selectedOrgId === null || selectedOrgId === 1) {
        const myId = JSON.parse(localStorage.getItem('respectiveActivityId'));
        if (myId) {
          response = await dispatch(participateInActivityCard({ activityIds: [myId], childCard: true }));
        }
      } else {
        const myId = JSON.parse(localStorage.getItem('respectiveActivityId'));
        if (myId) {
          response = await dispatch(participateInActivityCard({ activityIds: [myId], organizationId: selectedOrgId, childCard: true }));
        }
      }

      if (response.meta.requestStatus == 'fulfilled') {
        handleInstructionsModal(false);
        setIsButtonClicked(false);
        setOpenModal(true);
        if (fromProfile) {
          dispatch(fetchProfileDetails({ ...commonParams, eventType: 'Bookmarked' }));
        }
      } else {
        handleInstructionsModal(false);
      }
    } catch (error) {
      console.error('Error joining the activity:', error);
    }
  };
  useEffect(() => {
    if (!showAuthModal && isAuthenticated) {
      setAuthModalOpen(false);
    }
  }, [showAuthModal]);

  useEffect(() => {
    if (activity?.hasPermission) {
      setisVisible(true);
    }

    if (activity?.participant && !activity?.hasPermission) {
      setisVisible(true);
    }
    if (!activity?.hasPermission && !activity?.reported) {
      setisVisible(true);
    }
    if (!activity?.hasPermission && activity?.reported) {
      setisVisible(true);
    }
  }, [activity?.hasPermission, activity?.activityStatus, activity?.reported]);

  const [activityPostingRules, setactivityPostingRules] = useState<any>();
  useEffect(() => {
    setactivityPostingRules(JSON.parse(localStorage.getItem('postingRules')));
  }, [localStorage.getItem('postingRules')]);

  const VolunteerModal = (
    <Grid>
      {activity && (
        <Grid>
          <Dialog
            open={isVolunteerModalOpen && activity.id === currentActivityId}
            onClose={() => {
              handleInstructionsModal(false);
              handleCancel();
            }}
            PaperProps={{
              sx: {
                borderRadius: '15px',

                padding: '2%',
              },
            }}
          >
            <Stack direction="row" alignItems={'center'} justifyContent={'flex-end'}>
              <CloseIcon
                onClick={() => {
                  handleInstructionsModal(false);
                  handleCancel();
                }}
                sx={{ cursor: 'pointer' }}
              />
            </Stack>

            <DialogContent sx={{ height: 'auto', overflowY: 'hidden' }}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                {activityPostingRules && activityPostingRules?.length > 0 ? (
                  <Grid>
                    <Typography sx={{ fontSize: '14px/22px', color: isDarkMode ? '#FFCE00' : '#674BFF', marginBottom: '10px' }}>
                      Instructions for volunteers
                    </Typography>
                    <Paper
                      elevation={3}
                      sx={{
                        backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16))',
                        // p: 2,
                        height: '150px',
                        width: '100%',
                        overflowX: 'hidden',
                        boxShadow: 'none',
                        overflowY: activityPostingRules && activityPostingRules?.length > 2 ? 'scroll' : 'hidden',
                        scrollbarWidth: 'thin',
                        '&::-webkit-scrollbar': {
                          display: 'block',
                        },
                        '&::-webkit-scrollbar-track': {
                          backgroundColor: 'primary.light',
                        },
                        '&::-webkit-scrollbar-thumb': {
                          backgroundColor: '#AAA',
                          borderRadius: '16px',
                        },
                      }}
                    >
                      {activityPostingRules?.length
                        ? activityPostingRules?.map(item => (
                            <Stack key={item.id} direction="row" spacing={1} alignItems="flex-start" justifyContent={'flex-start'}>
                              <Stack p={0.7}>
                                <Box
                                  sx={{
                                    width: '9px',
                                    height: '9px',
                                    borderRadius: '50%',
                                    backgroundColor: '#775FFF',
                                  }}
                                />
                              </Stack>

                              <Stack direction="row" sx={{ color: theme.textColor.paragraph, gap: 0.2, px: { xs: 0 } }}>
                                <Typography sx={{ fontSize: theme.infoText.regular.fontSize }}>{item.description}</Typography>
                              </Stack>
                            </Stack>
                          ))
                        : null}
                    </Paper>
                  </Grid>
                ) : null}
              </div>
              {activity?.instructions && activity?.instructions?.length > 0 && (
                <div className="mt-2" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                  <Grid>
                    <Typography sx={{ fontSize: '14px/22px', color: isDarkMode ? '#FFCE00' : '#674BFF', marginBottom: '10px' }}>
                      Instructions by organizers
                    </Typography>

                    <Paper
                      elevation={3}
                      sx={{
                        backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16))',
                        p: 2,
                        pt: 0,
                        height: '141px',
                        width: '100%',
                        overflowX: 'hidden',
                        boxShadow: 'none',
                        overflowY: activity?.instructions && activity?.instructions?.length > 2 ? 'scroll' : 'hidden',
                        scrollbarWidth: 'none',
                        '&::-webkit-scrollbar': {
                          display: 'none',
                        },
                        '&::-webkit-scrollbar-track': {
                          backgroundColor: 'primary.light',
                        },
                        '&::-webkit-scrollbar-thumb': {
                          backgroundColor: '#AAA',
                          borderRadius: '16px',
                        },
                      }}
                    >
                      {activity?.instructions?.length
                        ? activity?.instructions?.map(item => (
                            <Stack key={item.id} direction="row" spacing={1} alignItems="flex-start" justifyContent={'flex-start'}>
                              <Stack p={0.7}>
                                <Box
                                  sx={{
                                    width: '9px',
                                    height: '9px',
                                    borderRadius: '50%',
                                    backgroundColor: '#775FFF',
                                  }}
                                />
                              </Stack>

                              <Stack direction="row" sx={{ color: theme.textColor.paragraph, gap: 0.2, px: { xs: 0 } }}>
                                <Typography sx={{ fontSize: theme.infoText.regular.fontSize }}>{item.instruction}</Typography>
                              </Stack>
                            </Stack>
                          ))
                        : null}
                    </Paper>
                  </Grid>
                </div>
              )}
            </DialogContent>

            <DialogActions
              sx={{
                justifyContent: 'center',
                textAlign: 'center',
              }}
            >
              <Stack
                direction={'column'}
                gap={1}
                sx={{
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Button
                  onClick={() => joinAndProceed()}
                  sx={{
                    background: theme.palette.primary.main,
                    color: '#292D34',
                    borderRadius: '15px',
                    width: '159px',
                    height: '40px',
                    '&:hover': {
                      background: theme.palette.primary.main,
                      color: '#292D34',
                      borderRadius: '15px',
                      width: '159px',
                      height: '40px',
                    },
                  }}
                  disabled={isButtonClicked}
                >
                  Join & proceed
                </Button>

                <Typography sx={{ fontSize: '14px', color: theme.textColor.heading }}>
                  By joining this event you accept the rules/instructions of this event.
                </Typography>
              </Stack>
            </DialogActions>
          </Dialog>
        </Grid>
      )}
    </Grid>
  );

  useEffect(() => {
    if (activity?.bookMark !== undefined) {
      setIsBookmarked(activity?.bookMark);
    }
  }, [activity?.bookMark]);

  useEffect(() => {
    if (activity?.liked !== undefined) {
      setIsLiked(activity.liked);
    }
  }, [activity?.liked]);

  useEffect(() => {
    if (activity?.likeCount !== undefined) {
      setLikeCountNumber(activity?.likeCount);
    }
  }, [activity?.likeCount]);

  const [phoneVerificationModal, setPhoneVerificationModal] = useState<boolean>(false);
  const handleVolunteerAs = async id => {
    localStorage.setItem('respectiveActivityId', id);
    if (isAuthenticated) {
      const res = await dispatch(fetchBanned({ userId: JSON.parse(localStorage.getItem('MyDetailsAccount'))?.id }));
      if (res?.payload) {
        toast.error(<>Action Restricted: You cannot perform this action as you are banned by the admin.</>);
        return;
      }
      const res1 = await dispatch(fetchBlocked({ userId: activity?.createdBy }));
      if (res1?.payload) {
        toast.error(<>Action Restricted: You cannot volunteer for this activity.</>);
        return;
      }
      if (phoneVerificationStatus) {
        if (userOwnedOrganizations.length > 0) {
          if (selectedOption && currentActivityId === id) {
            openVolunteerModal();
          } else {
            handleOrgsDialog(true);
            getCurrentActivity(id);
          }
        } else {
          handleOrgsDialog(false);
          changeOrgId(1);
          getCurrentActivity(id);
          openVolunteerModal();
        }
      } else {
        setPhoneVerificationModal(true);
      }
    } else {
      setCurrentComponent('login');
      dispatch(toggleModalLogin());

      setAuthModalOpen(true);
    }
  };

  const getShareToken = (socialName, content, activityId, type, URL, shareMessage) => {
    dispatch(fetchActivityShareToken({ activityId, type }))
      .then(result => {
        const token = result.payload.token;

        if (token) {
          const utmSource = socialName;
          const utmCampaign = 'Reachamy';
          const utmContent = content.replace(/\s/g, '');

          const newURL = `${URL}?utm_source=${utmSource}&utm_campaign=${utmCampaign}&utm_content=${utmContent}&t=${token}`;

          shareOnSocialMedia(socialName, newURL, shareMessage);
        }
      })
      .catch(err => {
        console.error('Error fetching data:', err);
        toast.error('Something went wrong');
      });
  };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  // const handleChange = event => {
  //   const selectedValue = event.target.value;

  //   if (selectedValue === 'My Self') {
  //     setSelectedOrgId(1);
  //     setSelectedOption('My Self');
  //   } else {
  //     const selectedOrganization = userOwnedOrganizations.find(organization => organization.id === selectedValue);

  //     if (selectedOrganization) {
  //       setSelectedOption(selectedOrganization.label);
  //       setSelectedOrgId(selectedOrganization.id);
  //     }
  //   }

  //   setVolunteerAnchorEl(null);
  // };

  const isOpen = Boolean(anchorEl);
  const id = isOpen ? 'simple-popover' : undefined;

  const toolTipText = !isAuthenticated
    ? null
    : isAuthenticated &&
      !activity?.hasPermission &&
      !activity?.participant &&
      activity?.activityStatus !== 'ENDED' &&
      activity?.activityStatus !== 'COMPLETED'
    ? 'Be a volunteer to access the link'
    : !activity?.hasPermission &&
      activity?.participant &&
      activity?.activityStatus === 'OPEN' &&
      Math.abs(new Date(activity.startTime).getTime() - new Date().getTime()) > 30 * 60 * 1000
    ? 'Link will be published 30 mins before the activity starts'
    : activity?.activityStatus === 'ENDED' || activity?.activityStatus === 'COMPLETED'
    ? 'This activity has ended.'
    : null;
  // const toolTipRemoteText = !isAuthenticated
  //   ? null
  //   : isAuthenticated &&
  //     !activity?.hasPermission &&
  //     Math.abs(new Date(activity.startTime).getTime() - new Date().getTime()) > 30 * 60 * 1000
  //   ? 'Link will be published 30 mins before the activity starts'
  //   : null;
  const [confirmFeature, setConfirmFeature] = React.useState<boolean>(false);
  const [payload, setPayload] = React.useState<any>();
  const [passId, setPassId] = React.useState<any>();

  const handleFeatureClick = (event: React.MouseEvent<HTMLButtonElement>, data: any) => {
    setPassId(data?.id);
    handleGetCatalogue(1);
  };

  const [paymentLoading, setPaymentLoading] = useState(false);
  const handlePayment = async () => {
    setPaymentLoading(true);
    let passData = {
      eventId: passId,
      eventType: 1,
      quantity: 1,
      productId: payload?.productId,
    };

    localStorage.setItem('passEventId', passData?.eventId);
    localStorage.setItem('passEventType', JSON.stringify(passData?.eventType));

    try {
      const response = await axios.post<any>('api/payment-service/checkout', passData);
      if (response.status === 201 || response.status === 200) {
        setPaymentLoading(false);
        const checkoutUrl = response?.data?.checkoutUrl;
        if (checkoutUrl) {
          window.location.href = checkoutUrl; // Open checkout URL in current tab
        } else {
          console.error('Checkout URL not found.');
        }
      }
    } catch (error) {
      setPaymentLoading(false);
      console.log(error);
    }
  };
  const handleGetCatalogue = async id => {
    try {
      const response = await axios.get<any>(`api/payment-service/product-catalogue?eventType=${id}`);
      if (response.status === 201 || response.status === 200) {
        setPayload(response.data);
        setConfirmFeature(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [phoneVerificationStatus, setPhoneVerificationStatus] = useState(true);
  const [phoneInputCompleted, setPhoneInputCompleted] = useState(false);
  const [otpVerificationCompleted, setOtpVerificationCompleted] = useState(false);
  const fetchPhoneNumber = async () => {
    try {
      const response = await dispatch(getPhoneNumber());
      if (response.meta.requestStatus == 'fulfilled') {
        localStorage.setItem('getPhone', JSON.stringify(response.payload));
        setPhoneVerificationStatus(response.payload?.verified);
      } else {
        // toast.error('Unable to fetch verification Status', { id: 'phone-verification-status-error' });
      }
    } catch (error) {}
  };

  useEffect(() => {
    const phoneDataString = localStorage.getItem('getPhone');
    if (phoneDataString) {
      try {
        const phoneData = JSON.parse(phoneDataString);
        if (typeof phoneData.verified === 'boolean') {
          setPhoneVerificationStatus(phoneData.verified);
        } else {
          console.error('Verified status is missing or invalid in localStorage data');
        }
      } catch (error) {
        console.error('Failed to parse phone data from localStorage:', error);
      }
    } else {
      console.log('No phone data found in localStorage');
    }
  }, [localStorage.getItem('getPhone')]);

  const handlePhoneVerificationClose = () => {
    setPhoneVerificationModal(false);
    if (otpVerificationCompleted) {
      fetchPhoneNumber();
    } else {
      setPhoneInputCompleted(false);
    }
  };

  const [index, setIndex] = useState(-1);
  const handleLightBoxClick = (index: number) => setIndex(index);
  const handleLightBoxClose = () => setIndex(-1);

  const slides: GallerySlide[] = activity?.eventGallery?.map(img => {
    if (img.type === 'videos') {
      return {
        type: 'video',
        src: img?.link,
        thumbnail: img?.thumbnail,
        title: '',
        sources: [{ src: img?.link, type: 'video/mp4' }],
      };
    } else {
      return {
        type: 'image',
        src: img?.link,
        thumbnail: img?.link,
        title: '',
      };
    }
  });

  return (
    <>
      <Card
        key={activity?.id}
        id={activity?.id}
        sx={{ minWidth: '100% ', backgroundColor: 'secondary.dark', boxShadow: 'none', borderRadius: '20px' }}
        onError={handleImageError}
      >
        <>
          <div style={{ height: '430px', position: 'relative', borderRadius: '15px', overflow: 'hidden' }}>
            {activity?.featured && (
              <div
                style={{
                  position: 'absolute',
                  top: '0',
                  left: '0',
                  background: '#674BFF',
                  color: '#FFFF',
                  padding: '5px 10px',
                  borderRadius: '0 0 15px 0',
                  zIndex: '999',
                  fontSize: '12px',
                }}
              >
                <span style={{ fontWeight: 'bold' }}>Featured</span>
              </div>
            )}
            <ImageGrid onImageClick={handleLightBoxClick}>
              {activity?.eventGallery?.map(item => (
                <div className={`img_container ${item.type === 'videos' ? 'video-grid' : ''}`}>
                  <img
                    alt="ig"
                    src={item.type === 'videos' ? item?.thumbnail : item?.link}
                    style={{ objectPosition: '1px 1%', objectFit: 'cover' }}
                  />
                </div>
              ))}
            </ImageGrid>
            <div
              className="legend d-flex justify-content-between align-items-center paddingLeft: '23px'"
              style={{
                position: 'absolute',
                background: 'rgba(0, 0, 0, 0.3)',
                right: 0,
                left: 0,
                bottom: 0,
                height: '40px',
                paddingLeft: '23px',
              }}
            >
              <div
                style={{
                  position: 'relative',
                  left: ' 15px',
                }}
              >
                <span className="d-flex  align-items-center ">
                  {activity?.eventParticipantsCount > 0 && (
                    <>
                      <AvatarGroup max={3} className="av-size" style={{ height: '20px', width: '20px', fontSize: 12 }}>
                        {activity?.participants.map((item): any => (
                          <Avatar
                            key={item?.id}
                            src={item?.profilePic}
                            alt={`${item?.profilePic}` ? '' : getInitials(item?.name)}
                            style={{
                              height: '20px',
                              width: '20px',
                              zIndex: '2',
                              fontSize: item?.profilePic ? 'default' : '12px',
                            }}
                          >
                            {getInitials(item?.name)}
                          </Avatar>
                        ))}
                      </AvatarGroup>
                      <p style={{ color: '#ffff', marginRight: '10px', marginLeft: '10px', fontSize: '11px', marginBottom: '0px' }}>
                        {activity?.eventParticipantsCount}
                      </p>

                      <Box
                        sx={{
                          width: '5px',
                          height: '5px',
                          borderRadius: '50%',
                          backgroundColor: '#ffff',
                        }}
                      />
                    </>
                  )}

                  {activity?.ageLimit !== null && activity?.ageLimit !== 0 && activity?.lostPet === null && (
                    <p
                      style={{ color: '#ffff', marginLeft: '10px', fontSize: '11px', marginBottom: '0px' }}
                    >{`age limit ${activity?.ageLimit}+`}</p>
                  )}
                </span>
              </div>

              <div>
                {isBookmarked ? (
                  <BookmarkIcon
                    sx={{
                      color: '#674BFF',
                      fontSize: '24px',
                      marginRight: '16px',
                      cursor: 'pointer',
                    }}
                    onClick={handleBookmarkClick}
                  />
                ) : (
                  <BookmarkBorderIcon
                    sx={{
                      color: '#ffff',
                      fontSize: '24px',
                      marginRight: '16px',
                      cursor: 'pointer',
                    }}
                    onClick={handleBookmarkClick}
                  />
                )}
              </div>
            </div>
          </div>
          <Lightbox
            carousel={{ finite: true }}
            open={index >= 0}
            close={handleLightBoxClose}
            slides={slides}
            plugins={[Video]}
            index={index}
          />
        </>

        <div
          style={{
            paddingLeft: isSmallerScreen ? '8px' : '16px',
            paddingRight: isSmallerScreen ? '8px' : '16px',
            paddingTop: isSmallerScreen ? '8px' : '1px',
            paddingBottom: isSmallerScreen ? '8px' : '1px',
          }}
        >
          <div className="row d-flex justify-content-between ">
            <Box display="flex" justifyContent="space-between" alignItems="flex-start" pt={1.6}>
              <Box display="flex" justifyContent="space-between" alignItems="center" gap={1}>
                <Avatar
                  onClick={() =>
                    activity?.postAsOrganisation
                      ? navigate(`/organization/${activity.organizationId}`)
                      : navigate(`/profile/${activity?.createdBy}`)
                  }
                  alt={`${activity?.postedByProfileImage}` ? '' : getInitials(activity?.postedBy)}
                  src={activity?.postedByProfileImage}
                  sx={{ width: '30px', height: '29px', cursor: 'pointer', fontSize: activity?.postedByProfileImage ? 'default' : '12px' }}
                >
                  {' '}
                  {getInitials(activity?.postedBy)}
                </Avatar>

                <Box display="flex" gap={1.6}>
                  <Box marginX={0.6} display="inline-flex" component="span" gap={0.5} alignItems="baseline">
                    <Link
                      style={{ fontWeight: 600, width: '100%' }}
                      to={activity?.postAsOrganisation ? `/organization/${activity.organizationId}` : `/profile/${activity?.createdBy}`}
                    >
                      {activity?.postedBy}
                      <Typography
                        component="span"
                        className="text-lowercase"
                        sx={{
                          font: 'normal normal normal 13px/21px Manrope',
                          color: '#9FA1A4',
                          mx: 1,
                          display: 'inline-block',
                        }}
                      >
                        <Moment fromNow>{activity?.createdAt}</Moment>
                      </Typography>
                    </Link>
                    {!activity?.makeThisPrivateActivity && (
                      <Tooltip title="This post is visible to anyone on Reach AMY ">
                        <a style={{ cursor: 'default', color: 'rgb(159, 161, 164)' }}>
                          <LanguageIcon />
                        </a>
                      </Tooltip>
                    )}
                  </Box>
                </Box>
              </Box>
              <Box display="flex" flexDirection="column" alignItems="flex-end">
                <Box
                  aria-label="notifications"
                  sx={{
                    padding: 'unset',
                    display: 'block',
                    '&:hover': {
                      backgroundColor: 'inherit',
                    },
                  }}
                  onClick={handleRemoteActivityButton}
                >
                  {activity?.online ? (
                    <Tooltip title={toolTipText}>
                      <Box display="flex" alignItems="center">
                        <RemoteEventIcon sx={{ fontSize: '16px', cursor: 'pointer' }} />
                        <small
                          style={{
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            cursor: 'pointer',
                            // textOverflow: 'ellipsis',
                            width: '90px',
                            marginLeft: '3px',
                            ...(isMdScreen ? locationXs : location),
                          }}
                        >
                          Remote event
                        </small>
                      </Box>
                    </Tooltip>
                  ) : (
                    <Link
                      style={{ display: 'flex', alignItems: 'center' }}
                      className="text-decoration-none"
                      target="_blank"
                      rel="noopener noreferrer"
                      to={
                        activity?.eventPlace
                          ? generateGMapsLinkPlace(activity?.eventPlace)
                          : activity.latitude && activity?.longitude
                          ? generateGMapsLinkCords(activity?.latitude, activity?.longitude)
                          : '#'
                      }
                    >
                      <LocationOnOutlinedIcon sx={{ color: isDarkMode ? '#BFBFBF' : '#5A5F67' }} />

                      {(places?.place || activity?.eventPlace) && (
                        <Tooltip title={(places?.place || activity?.eventPlace) ?? null}>
                          <small
                            style={{
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                              width: '79px',
                              ...(isMdScreen ? locationXs : location),
                            }}
                          >
                            {/* {activity?.eventPlace?.length > 13
                              ? activity?.eventPlace?.split(',')[0]?.length > 12
                                ? `${activity?.eventPlace?.split(',')[0]?.substring(0, 10)}...`
                                : activity?.eventPlace?.split(',')[0]
                              : activity?.eventPlace} */}
                            {activity?.eventPlace?.length > 13
                              ? activity?.eventPlace?.split(',')[0]?.length > 12
                                ? `${activity?.city},${activity?.country}`
                                : `${activity?.city},${activity?.country}`
                              : activity?.eventPlace}
                          </small>
                        </Tooltip>
                      )}
                    </Link>
                  )}
                </Box>
                <Box component="span" sx={{ font: '14px / 21px Manrope', color: 'rgb(103, 110, 123)', width: 'max-content' }}>
                  {!activity?.lostPet
                    ? `${convertDateToDay(activity.startDate)} @ ${getFormattedTime(activity.startTime)}`
                    : 'Lost on' + ` ${convertDateToYear(activity.lostPet?.dateLostOn)} `}
                </Box>
              </Box>
            </Box>

            <Box
              onClick={() => {
                handleButtonClick(activity);
              }}
            >
              <Button
                sx={{
                  fontSize: '13px',
                  borderRadius: '20px',
                  height: '28px',
                  whiteSpace: 'nowrap',
                  cursor: 'auto',
                  backgroundColor: theme.palette.mode === 'dark' ? '#3D3C52' : '#F6F7F8',
                  transition: 'background-color 0s ease',
                  color: theme.palette.mode === 'dark' ? 'secondary.contrastText' : '#362691',
                }}
              >
                {activity?.category?.category}
              </Button>

              {activity?.recurringId != null && (
                <Button
                  sx={{
                    fontSize: '13px',
                    borderRadius: '20px',
                    marginLeft: '6px',
                    height: '28px',
                    whiteSpace: 'nowrap',
                    cursor: 'auto',
                    backgroundColor: theme.palette.mode === 'dark' ? '#3D3C52' : '#F6F7F8',
                    transition: 'background-color 0s ease',
                    color: theme.palette.mode === 'dark' ? 'secondary.contrastText' : '#362691',
                  }}
                >
                  Recurring
                </Button>
              )}

              {activity?.lostPet != null && (
                <Button
                  className="ms-2"
                  sx={{
                    fontSize: '13px',
                    borderRadius: '20px',
                    height: '28px',
                    whiteSpace: 'nowrap',
                    cursor: 'auto',
                    backgroundColor: theme.palette.mode === 'dark' ? '#3D3C52' : '#F6F7F8',
                    transition: 'background-color 0s ease',
                    color: theme.palette.mode === 'dark' ? 'secondary.contrastText' : '#362691',
                  }}
                >
                  {activity?.lostPet?.animal}
                </Button>
              )}

              {((activity?.makeAsUrgentNeed && activity?.category?.id !== 19) || (activity?.lostPet && activity?.category?.id !== 19)) && (
                <Button
                  className="btn-urg"
                  variant="contained"
                  sx={{
                    fontSize: '13px',
                    marginLeft: '6px',
                    borderRadius: '20px',
                    height: '28px',
                    backgroundColor: theme.palette.mode === 'dark' ? '#3D3C52' : '#FF9494',
                    color: theme.palette.mode === 'dark' ? '#FF93A9' : '#710017',
                    boxShadow: 'none',
                    cursor: 'auto',
                    '&:hover': {
                      color: theme.palette.mode === 'dark' ? '#FF93A9' : '#710017',
                      backgroundColor: theme.palette.mode === 'dark' ? '#3D3C52' : '#FF9494',
                    },
                  }}
                >
                  Urgent
                </Button>
              )}

              {activity?.bloodDonation && (
                <Button
                  className="btn-urg"
                  variant="contained"
                  sx={{
                    fontSize: '13px',
                    marginLeft: '6px',
                    borderRadius: '20px',
                    height: '28px',
                    backgroundColor: theme.palette.mode === 'dark' ? '#3D3C52' : '#FF9494',
                    color: theme.palette.mode === 'dark' ? '#FF93A9' : '#710017',
                    boxShadow: 'none',
                    cursor: 'auto',
                    '&:hover': {
                      color: theme.palette.mode === 'dark' ? '#FF93A9' : '#710017',
                      backgroundColor: theme.palette.mode === 'dark' ? '#3D3C52' : '#FF9494',
                    },
                  }}
                >
                  Blood donation
                </Button>
              )}
            </Box>

            <div
              className="col-6"
              onClick={() => {
                handleButtonClick(activity);
              }}
            ></div>

            <CardContent
              onClick={() => {
                handleButtonClick(activity);
              }}
              sx={{ paddingBottom: 0 }}
            >
              <div
                style={{
                  font: ' normal normal 600 24px/32px Manrope',
                  textAlign: 'left',
                  letterSpacing: 0,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  handleButtonClick(activity);
                }}
              >
                {activity?.title}
              </div>

              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  color: theme.palette.secondary.light,
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxHeight: '6em',
                  lineClamp: 3,
                  WebkitLineClamp: 3,
                  cursor: 'pointer',
                }}
                onClick={() => handleButtonClick(activity)}
              >
                {activity?.caption ? (
                  <>
                    <span
                      style={{
                        color: theme.palette.mode === 'dark' ? theme.palette.secondary.contrastText : '#5A5F67',
                        cursor: 'pointer',
                      }}
                    >
                      {activity?.caption}
                    </span>

                    {activity?.caption?.length > 220 && <span style={{ cursor: 'pointer' }}>... Read more</span>}
                  </>
                ) : null}
              </Typography>
            </CardContent>

            <CardActions sx={{ marginBottom: '-23px', pr: 2, paddingTop: 0 }}>
              <div className="row" style={{ width: '105%' }}>
                <div className="col-md-6 col-12">
                  <div className=" d-flex">
                    <span className=" d-flex mt-3 mb-3 ms-2 me-2">
                      {isLiked ? (
                        <UpVoteFilled sx={{ cursor: 'pointer', fontSize: '19px' }} onClick={handleLikeClick} />
                      ) : (
                        <UpVoteOutlined sx={{ cursor: 'pointer', fontSize: '19px' }} onClick={handleLikeClick} />
                      )}

                      <p className="px-2 " style={{ fontSize: '14px' }}>
                        {likeCountNumber ?? 0}
                      </p>
                    </span>

                    <span className=" d-flex mt-3 mb-3 ms-2 me-2" onClick={() => sentParameters(activity)}>
                      <SquareChatIcon style={{ cursor: 'pointer', fontSize: '19px' }} />
                      <p className="px-1 px-md-2" style={{ fontSize: '14px' }}>
                        {activity?.chatMsgCount}
                      </p>
                    </span>

                    <span className=" d-flex mt-3 mb-3 ms-2 me-2">
                      <VisibilityIcon style={{ cursor: 'pointer', fontSize: '19px', color: '#676E7B' }} />
                      <p className="px-md-2 px-1" style={{ fontSize: '14px' }}>
                        {activity?.activityViewCount}
                      </p>
                    </span>

                    {!activity?.makeThisPrivateActivity && (
                      <span className=" d-flex mt-3 mb-3 ms-2 me-2" onClick={handleShareButtonClick}>
                        <ShareIcon aria-describedby={socialShareId} style={{ cursor: 'pointer', fontSize: '19px', color: '#676E7B' }} />
                        <p className="px-md-2 px-1"></p>
                      </span>
                    )}

                    <Popover
                      id={socialShareId}
                      open={Boolean(socialAnchorEl)}
                      anchorEl={socialAnchorEl}
                      onClose={handleSocialShareClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                    >
                      <ListItem sx={{ display: 'flex', flexDirection: 'column' }}>
                        {theme.palette.mode === 'dark' ? (
                          <BlueTwitterIcon
                            sx={{ cursor: 'pointer' }}
                            onClick={() =>
                              getShareToken(
                                'twitter',
                                activity?.title.slice(0, 10),
                                activity?.id,
                                1,
                                activity?.eventURL,
                                appConstants.SocialShareMessage
                              )
                            }
                          />
                        ) : (
                          <GrayTwitterIcon
                            sx={{ cursor: 'pointer' }}
                            onClick={() =>
                              getShareToken(
                                'twitter',
                                activity?.title.slice(0, 10),
                                activity?.id,
                                1,
                                activity?.eventURL,
                                appConstants.SocialShareMessage
                              )
                            }
                          />
                        )}
                      </ListItem>

                      <ListItem sx={{ display: 'flex', flexDirection: 'column' }}>
                        <FacebookIcon
                          sx={{ color: theme.palette.mode === 'dark' ? '#1D9BF0' : '#676e7b', cursor: 'pointer' }}
                          onClick={() =>
                            getShareToken(
                              'facebook',
                              activity?.title.slice(0, 10),
                              activity?.id,
                              2,
                              activity?.eventURL,
                              appConstants.SocialShareMessage
                            )
                          }
                        />
                      </ListItem>

                      <ListItem sx={{ display: 'flex', flexDirection: 'column' }}>
                        <LinkedInIcon
                          sx={{ color: theme.palette.mode === 'dark' ? '#1D9BF0' : '#676e7b', cursor: 'pointer' }}
                          onClick={() =>
                            getShareToken(
                              'linkedIn',
                              activity?.title.slice(0, 10),
                              activity?.id,
                              3,
                              activity?.eventURL,
                              appConstants.SocialShareMessage
                            )
                          }
                        />
                      </ListItem>
                    </Popover>

                    {isAuthenticated && isVisible && (
                      <span className=" d-flex ms-2  mt-3 mb-3 " onClick={handleClick}>
                        <MoreHorizOutlinedIcon
                          aria-describedby={'menuItemId'}
                          sx={{ cursor: 'pointer', fontSize: '19px', color: '#676E7B' }}
                        />
                      </span>
                    )}

                    {activity?.hasPermission === true && (
                      <Popover
                        id={menuItemId}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                      >
                        <ListItem
                          sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left', alignItems: 'inherit', padding: '8px 16px' }}
                        >
                          {activity?.hasPermission === true && (
                            <>
                              {/* view edit activity option */}

                              {activity?.participants?.length === 1 &&
                                activity?.activityStatus !== 'ENDED' &&
                                activity?.activityStatus !== 'COMPLETED' && (
                                  <ListItemText>
                                    <Link to={`/edit-activity/${activity?.id}`} className="text-decoration-none">
                                      <Typography sx={{ p: 0, cursor: 'pointer', fontSize: '14px' }}> Edit activity</Typography>
                                    </Link>
                                  </ListItemText>
                                )}

                              {/* edit activity option */}
                              {/* {activity?.participants?.length === 1 &&
                                activity?.activityStatus !== 'ENDED' &&
                                activity?.activityStatus !== 'COMPLETED' && (
                                  <ListItemText
                                    onClick={() => {
                                      handleClose();

                                      setConfirmDelete(true);
                                    }}
                                  >
                                    <Typography sx={{ p: 0, cursor: 'pointer', fontSize: '14px' }}> Delete activity</Typography>
                                  </ListItemText>
                                )} */}

                              {activity?.activityStatus === 'OPEN' && (
                                <ListItemText
                                  onClick={() => {
                                    handleClose();

                                    setConfirmDelete(true);
                                  }}
                                >
                                  <Typography sx={{ p: 0, cursor: 'pointer', fontSize: '14px' }}> Delete activity</Typography>
                                </ListItemText>
                              )}

                              {activity?.activityStatus === 'RUNNING' && activity?.participants?.length === 1 && (
                                <ListItemText
                                  onClick={() => {
                                    handleClose();

                                    setConfirmDelete(true);
                                  }}
                                >
                                  <Typography sx={{ p: 0, cursor: 'pointer', fontSize: '14px' }}> Delete activity</Typography>
                                </ListItemText>
                              )}

                              {activity?.permittedToFeatured === true && (
                                <button
                                  style={{ padding: 0, background: 'none', border: 'none', cursor: 'pointer' }}
                                  onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                    handleFeatureClick(event, activity);
                                  }}
                                >
                                  <ListItemText className="text-start">
                                    <Typography sx={{ p: 0, fontSize: '14px', cursor: 'pointer', color: isDarkMode ? '#fff' : '#000' }}>
                                      Feature activity
                                    </Typography>
                                  </ListItemText>
                                </button>
                              )}
                            </>
                          )}
                        </ListItem>
                      </Popover>
                    )}
                    {!activity?.hasPermission && (
                      <Popover
                        id={menuItemId}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                      >
                        <ListItem
                          sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left', alignItems: 'inherit', padding: '8px 16px' }}
                        >
                          {activity?.participant && !activity?.hasPermission && activity?.activityStatus === 'OPEN' && (
                            <ListItemText onClick={() => handleLeave()}>
                              <Typography sx={{ p: 0, cursor: 'pointer', fontSize: '14px' }}>Leave activity</Typography>
                            </ListItemText>
                          )}

                          {activity?.participant &&
                            !activity?.hasPermission &&
                            activity?.activityStatus === 'RUNNING' &&
                            activity?.lostPet && (
                              <ListItemText onClick={() => handleLeave()}>
                                <Typography sx={{ p: 0, cursor: 'pointer', fontSize: '14px' }}>Leave activity</Typography>
                              </ListItemText>
                            )}

                          {activity?.permittedToFeatured === true && (
                            <button
                              style={{ padding: 0, background: 'none', border: 'none', cursor: 'pointer' }}
                              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                handleFeatureClick(event, activity);
                              }}
                            >
                              <ListItemText className="text-start">
                                <Typography sx={{ p: 0, fontSize: '14px', cursor: 'pointer', color: isDarkMode ? '#fff' : '#000' }}>
                                  Feature activity
                                </Typography>
                              </ListItemText>
                            </button>
                          )}

                          {/* view report activity option  */}

                          {!activity?.hasPermission && !activity?.reported ? (
                            <ListItemText onClick={() => handleReport()}>
                              <Typography sx={{ p: 0, cursor: 'pointer', fontSize: '14px' }}>Report activity</Typography>
                            </ListItemText>
                          ) : !activity?.hasPermission && activity?.reported ? (
                            <ListItemText>
                              <Typography sx={{ p: 0, cursor: 'pointer', fontSize: '14px' }}>Reported</Typography>
                            </ListItemText>
                          ) : null}
                        </ListItem>
                      </Popover>
                    )}
                  </div>
                </div>

                <div className="col-md-6 col-12 p-2 text-end" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {!activity?.participant && activity?.activityStatus == 'OPEN' && !activity?.makeThisPrivateActivity && (
                    <>
                      <Button
                        variant="contained"
                        className="me-1"
                        sx={{
                          fontSize: '14px',
                          borderRadius: '20px',
                          height: '28px',
                          lineHeight: '12px',
                          '&:hover': {
                            backgroundColor: 'primary.main',
                            color: 'common.black',
                          },
                          width: 'fit-content',
                        }}
                        onClick={() => handleVolunteerAs(activity.id)}
                      >
                        {buttonText}
                      </Button>
                    </>
                  )}

                  {!activity?.participant &&
                    activity?.activityStatus == 'OPEN' &&
                    activity?.makeThisPrivateActivity &&
                    activity?.invited && (
                      <>
                        <Button
                          variant="contained"
                          className="me-1"
                          sx={{
                            fontSize: '14px',
                            borderRadius: '20px',
                            height: '28px',
                            lineHeight: '12px',
                            '&:hover': {
                              backgroundColor: 'primary.main',
                              color: 'common.black',
                            },
                            width: 'fit-content',
                          }}
                          onClick={() => handleVolunteerAs(activity.id)}
                        >
                          {buttonText}
                        </Button>
                      </>
                    )}

                  {activity?.participant && !activity?.hasPermission && activity?.activityStatus == 'OPEN' && (
                    <Button
                      variant="text"
                      className="me-1"
                      style={{ cursor: 'default' }}
                      sx={{
                        color: isDarkMode ? '#FFCE00' : '#674BFF',
                        fontSize: '14px',
                        borderRadius: '20px',
                        height: '28px',
                        lineHeight: '12px',
                        '&:hover': {
                          backgroundColor: 'none',
                        },
                      }}
                    >
                      Thanks for volunteering
                    </Button>
                  )}
                  {!activity?.hasPermission &&
                    !activity?.invited &&
                    !activity?.participant &&
                    activity?.makeThisPrivateActivity &&
                    activity?.activityStatus == 'OPEN' && (
                      <Button
                        variant="text"
                        className="me-1"
                        style={{ cursor: 'default' }}
                        sx={{
                          color: isDarkMode ? '#FFCE00' : '#674BFF',
                          fontSize: '14px',
                          borderRadius: '20px',
                          height: '28px',
                          lineHeight: '12px',
                          '&:hover': {
                            backgroundColor: 'none',
                          },
                        }}
                      >
                        By invite only
                      </Button>
                    )}
                  {activity?.participant && activity?.hasPermission && activity?.activityStatus == 'OPEN' && (
                    <Button
                      variant="contained"
                      className="me-1"
                      style={{ cursor: 'pointer' }}
                      // sx={{
                      //   color: isDarkMode ? '#FFCE00' : '#674BFF',
                      //   fontSize: '14px',
                      //   borderRadius: '20px',
                      //   height: '28px',
                      //   lineHeight: '12px',
                      //   '&:hover': {
                      //     backgroundColor: 'none',
                      //   },
                      // }}
                      sx={{
                        fontSize: '14px',
                        borderRadius: '20px',
                        height: '28px',
                        lineHeight: '12px',
                        '&:hover': {
                          backgroundColor: 'primary.main',
                          color: 'common.black',
                        },
                        width: 'fit-content',
                      }}
                      onClick={() => setOpenInviteModal(true)}
                    >
                      Thanks! Invite More
                    </Button>
                  )}

                  {activity?.participant &&
                    activity?.reached &&
                    activity?.activityStatus === 'ENDED' &&
                    !activity?.rated &&
                    myProfileId !== activity?.createdBy && (
                      <Button
                        variant="contained"
                        sx={{
                          fontSize: '14px',
                          borderRadius: '20px',
                          height: '28px',
                          lineHeight: '12px',
                          textWrap: 'nowrap',
                          '&:hover': {
                            backgroundColor: 'primary.main',
                            color: 'common.black',
                          },
                          width: 'fit-content',
                        }}
                        onClick={() => openRatingModal()}
                      >
                        {ratingButtonText}
                      </Button>
                    )}

                  {activity?.activityStatus === 'RUNNING' && activity?.category?.id !== 19 && (
                    <Button
                      variant="text"
                      sx={{
                        fontSize: '14px',
                        borderRadius: '20px',
                        height: '28px',
                        lineHeight: '12px',
                        cursor: 'auto',
                        color: isDarkMode ? '#FFCE00' : '#674BFF',
                        '&:hover': {
                          color: isDarkMode ? '#FFCE00' : '#674BFF',
                        },
                      }}
                    >
                      Activity started
                    </Button>
                  )}

                  {activity?.activityStatus === 'RUNNING' && activity?.category?.id === 19 && !lostDone && activity?.hasPermission && (
                    <Button
                      variant="contained"
                      sx={{
                        fontSize: '14px',
                        borderRadius: '20px',
                        height: '28px',
                        lineHeight: '12px',
                        '&:hover': {
                          backgroundColor: 'primary.main',
                          color: 'common.black',
                        },
                        width: 'fit-content',
                      }}
                      onClick={() => {
                        handleClose();

                        setConfirmLostPet(true);
                      }}
                    >
                      Mark as done
                    </Button>
                  )}

                  {activity?.activityStatus === 'RUNNING' && activity?.category?.id === 19 && lostDone && activity?.hasPermission && (
                    <Button
                      variant="text"
                      sx={{
                        fontSize: '14px',
                        borderRadius: '20px',
                        height: '28px',
                        lineHeight: '12px',
                        cursor: 'auto',
                        color: isDarkMode ? '#FFCE00' : '#674BFF',
                        '&:hover': {
                          color: isDarkMode ? '#FFCE00' : '#674BFF',
                        },
                      }}
                    >
                      Activity completed
                    </Button>
                  )}

                  {activity?.activityStatus === 'RUNNING' &&
                    activity?.category?.id === 19 &&
                    activity?.participant &&
                    !activity?.organiser && (
                      <Button
                        variant="text"
                        sx={{
                          fontSize: '14px',
                          borderRadius: '20px',
                          height: '28px',
                          lineHeight: '12px',
                          cursor: 'auto',
                          color: isDarkMode ? '#FFCE00' : '#674BFF',
                          '&:hover': {
                            color: isDarkMode ? '#FFCE00' : '#674BFF',
                          },
                        }}
                      >
                        Thanks for volunteering
                      </Button>
                    )}

                  {activity?.activityStatus === 'RUNNING' &&
                    activity?.category?.id === 19 &&
                    !activity?.participant &&
                    !activity?.organiser && (
                      <Button
                        variant="contained"
                        className="me-1"
                        sx={{
                          fontSize: '14px',
                          borderRadius: '20px',
                          height: '28px',
                          lineHeight: '12px',
                          '&:hover': {
                            backgroundColor: 'primary.main',
                            color: 'common.black',
                          },
                          width: 'fit-content',
                        }}
                        onClick={() => handleVolunteerAs(activity.id)}
                      >
                        {buttonText}
                      </Button>
                    )}
                  {activity?.activityStatus === 'COMPLETED' && activity?.starRating < 4 && activity?.starRating !== null && (
                    <Button
                      variant="text"
                      style={{
                        cursor: 'default',
                      }}
                      sx={{
                        fontSize: '14px',
                        borderRadius: '20px',
                        height: '28px',
                        lineHeight: '12px',
                        color: isDarkMode ? '#FFCE00' : '#674BFF',
                        '&:hover': {
                          color: isDarkMode ? '#FFCE00' : '#674BFF',
                        },
                      }}
                    >
                      Activity completed
                    </Button>
                  )}

                  {activity?.activityStatus === 'COMPLETED' && activity?.starRating === null && (
                    <Button
                      variant="text"
                      style={{
                        cursor: 'default',
                      }}
                      sx={{
                        fontSize: '14px',
                        borderRadius: '20px',
                        height: '28px',
                        lineHeight: '12px',
                        color: isDarkMode ? '#FFCE00' : '#674BFF',
                        '&:hover': {
                          color: isDarkMode ? '#FFCE00' : '#674BFF',
                        },
                      }}
                    >
                      Activity completed
                    </Button>
                  )}

                  {activity?.activityStatus === 'ENDED' &&
                    activity?.participant &&
                    activity?.reached &&
                    activity?.starRating < 4 &&
                    activity?.starRating !== null && (
                      <Button
                        variant="text"
                        style={{
                          cursor: 'default',
                        }}
                        sx={{
                          fontSize: '14px',
                          borderRadius: '20px',
                          height: '28px',
                          textWrap: 'nowrap',
                          lineHeight: '12px',
                          color: isDarkMode ? '#FFCE00' : '#674BFF',
                          '&:hover': {
                            color: isDarkMode ? '#FFCE00' : '#674BFF',
                          },
                        }}
                      >
                        Pending feedback
                      </Button>
                    )}
                  {activity?.activityStatus === 'ENDED' &&
                    !activity?.reached &&
                    !activity?.hasPermission &&
                    (activity?.starRating === 0 || activity?.starRating === null) && (
                      <Button
                        variant="text"
                        style={{
                          cursor: 'default',
                        }}
                        sx={{
                          fontSize: '14px',
                          borderRadius: '20px',
                          height: '28px',
                          textWrap: 'nowrap',
                          lineHeight: '12px',
                          color: isDarkMode ? '#FFCE00' : '#674BFF',
                          '&:hover': {
                            color: isDarkMode ? '#FFCE00' : '#674BFF',
                          },
                        }}
                      >
                        Pending feedback
                      </Button>
                    )}

                  {activity?.activityStatus === 'ENDED' &&
                    activity?.hasPermission &&
                    (activity?.starRating === 0 || activity?.starRating === null) && (
                      <Button
                        variant="text"
                        style={{
                          cursor: 'default',
                        }}
                        sx={{
                          fontSize: '14px',
                          borderRadius: '20px',
                          height: '28px',
                          textWrap: 'nowrap',
                          lineHeight: '12px',
                          color: isDarkMode ? '#FFCE00' : '#674BFF',
                          '&:hover': {
                            color: isDarkMode ? '#FFCE00' : '#674BFF',
                          },
                        }}
                      >
                        Pending feedback
                      </Button>
                    )}

                  {activity?.starRating > 3.9 && (
                    <Stack direction={'row'} gap={1} alignItems={'center'} marginBottom={'1.5rem'} marginLeft={'3px'}>
                      <Tooltip title="Highly Rated" arrow>
                        <MilitaryTechIcon color="primary" sx={{ width: '24px', height: '24px', fontSize: '16px' }} />
                      </Tooltip>
                    </Stack>
                  )}
                </div>
              </div>
            </CardActions>

            {selectedOrgId && isVolunteerModalOpen && VolunteerModal}

            <GenericModal
              handleClose={() => setConfirmDelete(false)}
              fullScreen={fullScreen}
              open={confirmDelete}
              title="Are you sure want to delete?"
              buttons={modalActionButtons}
            />

            <GenericModal
              handleClose={() => setConfirmLostPet(false)}
              fullScreen={fullScreen}
              open={confirmLostPet}
              title="Are you sure you want to mark this activity as done?"
              buttons={modalActionLostButtons}
            />

            <Dialog
              fullScreen={fullScreen}
              open={openRemoteModal}
              onClose={handleRemoteActivityClose}
              aria-labelledby="remote-activity-modal"
              PaperProps={{
                sx: {
                  borderRadius: { xs: 0, sm: '14px' },
                  boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
                  width: { lg: 400 },
                },
              }}
            >
              <Stack direction="column" sx={{ p: 2 }} spacing={3} minHeight={200}>
                <Stack direction="row" alignItems={'center'} justifyContent={'flex-end'}>
                  <CloseIcon onClick={handleRemoteActivityClose} sx={{ cursor: 'pointer' }} />
                </Stack>
                {activity?.hasPermission ? (
                  <>
                    <Typography variant="h4" textAlign={'center'}>
                      Meeting Link
                    </Typography>
                    <Button
                      variant="outlined"
                      sx={{ borderColor: 'primary.light', py: 1, borderRadius: '20px', '&:hover': { borderColor: 'primary.light' } }}
                    >
                      <Link to={activity?.onlineUrl} target="_blank" rel="noopener noreferrer">
                        <Stack direction="column" alignItems={'center'}>
                          <Typography
                            sx={{
                              wordBreak: 'break-word',
                              font: 'normal normal normal 14px/23px Manrope',
                              color: isDarkMode ? theme.palette.secondary.contrastText : '#676E7B',
                            }}
                          >
                            {activity?.onlineUrl}
                          </Typography>
                        </Stack>
                      </Link>
                    </Button>
                  </>
                ) : Math.abs(new Date(activity.startTime).getTime() - new Date().getTime()) <= 30 * 60 * 1000 ? (
                  <>
                    <Typography variant="h4" textAlign={'center'}>
                      Meeting Link
                    </Typography>
                    <Button
                      variant="outlined"
                      sx={{ borderColor: 'primary.light', py: 1, borderRadius: '20px', '&:hover': { borderColor: 'primary.light' } }}
                    >
                      <Link to={activity?.onlineUrl} target="_blank" rel="noopener noreferrer" onClick={handleRemote}>
                        <Stack direction="column" alignItems={'center'}>
                          <Typography
                            sx={{
                              wordBreak: 'break-word',
                              font: 'normal normal normal 14px/23px Manrope',
                              color: isDarkMode ? theme.palette.secondary.contrastText : '#676E7B',
                            }}
                          >
                            {activity?.onlineUrl}
                          </Typography>
                        </Stack>
                      </Link>
                    </Button>
                  </>
                ) : (
                  <p
                    style={{
                      fontSize: '16px',
                      color: isDarkMode ? theme.palette.secondary.contrastText : '#676E7B',
                      textAlign: 'center',
                    }}
                  >
                    Link will be published 30 mins before the activity starts
                  </p>
                )}
              </Stack>
            </Dialog>
          </div>
        </div>

        {/* login modal */}

        <AuthorisationModal
          currentComponent={currentComponent}
          fullScreen={fullScreen}
          authModalOpen={authModalOpen}
          onClose={handleAuthModalClose}
          handleAuthModalClose={handleAuthModalClose}
          setCurrentComponent={setCurrentComponent}
        />

        <JoiningSuccessModal
          handleClose={handleModalClose}
          fullScreen={fullScreen}
          open={openModal}
          url={'chat url'}
          title="Thank you for volunteering."
        />

        <LeaveActivityDialog open={leaveDialogOpen} onClose={() => handleLeaveClose()} handleData={activity} />
        <ReportActivityDialog open={reportDialogOpen} onClose={() => handleReportClose()} handleData={activity} />
        <FeatureDialog
          paymentLoading={paymentLoading}
          agreeAction={handlePayment}
          values={payload}
          open={confirmFeature}
          setFeatureOpen={setConfirmFeature}
        />
        <ActivityRatingModal
          open={isRatingModalOpen}
          onClose={() => setIsRatingModalOpen(false)}
          handleClose={handleRatingModalClose}
          activity={activity}
        />
        <PhoneVerification
          handleClose={handlePhoneVerificationClose}
          open={phoneVerificationModal}
          fullScreen={isSmallerScreen}
          phoneInputCompleted={phoneInputCompleted}
          setPhoneInputCompleted={setPhoneInputCompleted}
          otpVerificationCompleted={otpVerificationCompleted}
          setotpVerificationCompleted={setOtpVerificationCompleted}
        />
        <InviteMoreModal
          handleModalClose={handleInviteModalClose}
          fullScreen={fullScreen}
          open={openInviteModal}
          onClose={handleInviteModalClose}
          responseUrl={webUrl}
          pdfUrl={''}
          message="Invite  your friends"
        />
        {/* <PostSuccessModal
        handleModalClose={handleModalClose}
        fullScreen={fullScreen}
        open={open}
        onClose={handleModalClose}
        responseUrl={'like'}
        pdfUrl={'null'}
        message="share"
      /> */}
      </Card>
    </>
  );
};

export default SingleActivityCard;
