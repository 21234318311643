import { useAppDispatch } from 'app/config/store';
import { addMessage } from 'app/shared/reducers/chat-messages/chat-Panel';
import React, { createContext, useRef, useState, useEffect, ReactNode } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useLocation } from 'react-router-dom';

interface WebSocketContextType {
  webSocket: React.MutableRefObject<WebSocket | null>;
  isConnected: boolean;
  disconnect: () => void;
  sendMessage: (message: string) => void;
  messageTrigger: boolean;
}

const defaultContextValue: WebSocketContextType = {
  webSocket: { current: null },
  isConnected: false,
  disconnect: () => console.warn('WebSocketProvider is not yet initialized'),
  sendMessage: (message: string) => console.warn('sendMessage function is not yet initialized'),
  messageTrigger: false,
};

const WebSocketContext = createContext<WebSocketContextType>(defaultContextValue);

interface WebSocketProviderProps {
  children: ReactNode;
}

export const WebSocketProvider: React.FC<WebSocketProviderProps> = ({ children }) => {
  const [isConnected, setIsConnected] = useState(false);
  const [messageTrigger, setMessageTrigger] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const webSocket = useRef<WebSocket | null>(null);

  const token = JSON.parse(localStorage?.getItem('jhi-authenticationToken')) || '';
  const id = JSON.parse(localStorage?.getItem('MyDetailsAccount'))?.id || '';

  const connectWebSocket = () => {
    if (webSocket.current && (webSocket.current.readyState === WebSocket.OPEN || webSocket.current.readyState === WebSocket.CONNECTING)) {
      console.log('WebSocket is already connected or in the process of connecting');
      return;
    }

    const getBaseDomain = (url: string) => {
      const urlParts = url.split('/');
      return urlParts[2];
    };

    const domain = getBaseDomain(window.location.href);
    const socketUrl = `wss://${domain}/websocket?token=${token}&userId=${id}`;
    webSocket.current = new WebSocket(socketUrl);
    console.log('WebSocket URL:', socketUrl);

    webSocket.current.onopen = () => {
      console.log('WebSocket connection established');
      setIsConnected(true);
    };

    webSocket.current.onerror = error => {
      console.error('WebSocket error:', error);
      setIsConnected(false);
    };

    webSocket.current.onclose = event => {
      console.log('WebSocket connection closed:', event);
      setIsConnected(false);
    };

    webSocket.current.onmessage = event => {
      const message = event.data;
      console.log('Received message:', message);
      setIsConnected(true);
      setMessageTrigger(!message);
    };
  };

  useEffect(() => {
    connectWebSocket();

    return () => {
      if (webSocket.current) {
        webSocket.current.close();
        console.log('WebSocket connection closed on component unmount');
      }
    };
  }, [token, id]);

  const disconnect = () => {
    if (webSocket.current && webSocket.current.readyState === WebSocket.OPEN) {
      console.log('checking connection on');
      // webSocket.current.close();
      // setIsConnected(false);
    }
  };

  const sendMessage = (message: string) => {
    if (webSocket.current && isConnected) {
      webSocket.current.send(message);
      console.log('Message sent:', message);
    } else {
      console.log('WebSocket is not connected');
    }
  };

  return (
    <WebSocketContext.Provider value={{ webSocket, isConnected, disconnect, sendMessage, messageTrigger }}>
      {children}
    </WebSocketContext.Provider>
  );
};

export default WebSocketContext;
