import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import _ from 'lodash';
import { fetchBookmarkedActivity, leavePetition, reportPetition, signedPetition } from './bookmark-activity';
import { deleteActivityByIds } from './activity-home';
import { participateInActivityCard } from './volunteer';
import { LeaveActivity } from './activity/activity-actions';
import { ReportActivity } from './activity/activity-report';
import { deletePetitionById, signPetition } from './petition-home';
import { rateActivity } from './activity/activity-rating';
import { fetchLikes } from './activity-like';
import { Satellite } from '@mui/icons-material';

function extractNumberFromString(str: any) {
  const match = str.match(/\d+/);
  if (match) {
    return parseInt(match[0], 10);
  } else {
    return null;
  }
}

interface Filter {
  categoryId: number | null;
  subCategoryId: number | null;
  country: string | null;
  state: string | null;
  city: string | null;
  organizationId: number | null;
  remoteEvents: boolean | null;
  petition: boolean | null;
  mySelf: boolean | null;
  featured: boolean | null;
  currentLatitude: any | null;
  currentLongitude: any | null;
  nearMe: {
    latitude: string | null;
    longitude: string | null;
  } | null;
}

export interface RequestBodyType {
  filter: Filter;
  eventType: string;
  page: number;
}

export interface RequestlatitudeType {
  latitude: number;
  longitude: number;
}

export interface initialFilterActivityState {
  tabType: string;
  activities: any;
  loading: boolean;
  error: string | null;
  RequestBody: RequestBodyType;
  searchResults: Array<any>;
  searchKeyWord: string;
  currentPage: number;
  searchCount: number;
  previousEventType: string | null;
  previousFilter: any;
  volunteeringEventId: number;
  isFromDetailsPage: boolean;
  prevItemId: number;
  lastSearchArg: any;
  lastResult: any;
  mapResults: Array<any>;
}

export const initialState: initialFilterActivityState = {
  tabType: '',
  activities: [],
  loading: false,
  error: null,
  searchCount: 0,
  RequestBody: {
    filter: {
      categoryId: null,
      subCategoryId: null,
      country: null,
      state: null,
      city: null,
      organizationId: null,
      remoteEvents: false,
      petition: false,
      mySelf: false,
      nearMe: null,
      featured: false,
      currentLatitude: JSON.parse(localStorage.getItem('currentLatitude')),
      currentLongitude: JSON.parse(localStorage.getItem('currentLongitude')),
    },
    eventType: 'Latest',
    page: 0,
  },
  searchResults: [],
  searchKeyWord: '',
  currentPage: 0,
  previousEventType: '',
  previousFilter: {},
  volunteeringEventId: null,
  isFromDetailsPage: false,
  prevItemId: null,
  mapResults: [],
  lastSearchArg: null,
  lastResult: [],
};

function toUTCString(date: any) {
  const d = new Date(date);
  return d.toISOString();
}

export const filterActivities = createAsyncThunk<any, RequestBodyType, { rejectValue: string }>(
  'activity/filterActivities',
  async (requestBody, { rejectWithValue }) => {
    try {
      localStorage.setItem('selectedCategory', JSON.stringify(requestBody?.filter));

      const axiosResponse = await axios.post<any>('api/home/filter', requestBody);
      return axiosResponse.data;
    } catch (error) {
      throw error.response?.data?.message || 'An error occurred';
    }
  }
);

export const filterActivitiesUnauthorized = createAsyncThunk<any, RequestBodyType, { rejectValue: string }>(
  'activity/filterActivitiesunauth',
  async (requestBody, { rejectWithValue }) => {
    try {
      const axiosResponse = await axios.post<any>('api/unauthorized/home/filter', requestBody);
      return axiosResponse.data;
    } catch (error) {
      throw error.response?.data?.message || 'An error occurred';
    }
  }
);
export const fetchActivityDatas = createAsyncThunk<any, RequestlatitudeType, { rejectValue: string }>(
  'activity/fetchActivityDatas',
  async (requestBody, { rejectWithValue }) => {
    try {
      const axiosResponse = await axios.post<any>('api/home/near-by-activities-and-petitions', requestBody);
      return axiosResponse.data;
    } catch (error) {
      throw error.response?.data?.message || 'An error occurred';
    }
  }
);
export const fetchActivityUnauthorizedDatas = createAsyncThunk<any, RequestlatitudeType, { rejectValue: string }>(
  'activity/fetchActivityUnauthorizedDatas',
  async (requestBody, { rejectWithValue }) => {
    try {
      const axiosResponse = await axios.post<any>('api/unauthorized/home/near-by-activities', requestBody);
      return axiosResponse.data;
    } catch (error) {
      throw error.response?.data?.message || 'An error occurred';
    }
  }
);
export const searchBlogs = createAsyncThunk<any, { keyword: string; startDate?: any; endDate?: any }, { rejectValue: string }>(
  'activity/serchBlogs',
  async ({ keyword }, { rejectWithValue }) => {
    try {
      const axiosResponse = await axios.get<any>(`api/home/search/blogs?keyword=${keyword}`);
      return axiosResponse.data;
    } catch (error) {
      throw error.response?.data?.message || 'An error occurred';
    }
  }
);

export const searchActivities = createAsyncThunk<
  any,
  { keyword: string; startDate?: any; endDate?: any; page?: any },
  { rejectValue: string }
>('activity/searchActivities', async ({ keyword, startDate, endDate, page }, { rejectWithValue }) => {
  try {
    const requestBody = {
      keyword,
      startDate: startDate || '',
      endDate: endDate || '',
    };
    const pageNumber = page || 0;
    const pageSize = 10;

    const axiosResponse = await axios.post<any>(`api/home/search?page=${pageNumber}&pageSize=${pageSize}`, requestBody);
    return axiosResponse.data;
  } catch (error) {
    throw error.response?.data?.message || 'An error occurred';
  }
});

export const searchActivitiesCount = createAsyncThunk<any, { keyword: string; startDate?: any; endDate?: any }, { rejectValue: string }>(
  'activity/searchActivitiesCount',
  async ({ keyword, startDate, endDate }, { rejectWithValue }) => {
    try {
      let url = `api/home/search/count?keyword=${keyword}`;
      if (startDate) {
        url += `&startDate=${startDate ? startDate : ''}`;
      } else {
        url += `&startDate=`;
      }

      if (endDate) {
        url += `&endDate=${endDate ? endDate : ''}`;
      } else {
        url += `&endDate=`;
      }

      const axiosResponse = await axios.get<any>(url);
      return axiosResponse.data;
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'An error occurred';
      return rejectWithValue(errorMessage);
    }
  }
);
export const searchUnauthorizedActivitiesCount = createAsyncThunk<
  any,
  { keyword: string; startDate?: any; endDate?: any },
  { rejectValue: string }
>('activity/searchUnauthorizedActivitiesCount', async ({ keyword, startDate, endDate }, { rejectWithValue }) => {
  try {
    let url = `api/unauthorized/home/search/count?keyword=${keyword}`;
    if (startDate) {
      url += `&startDate=${startDate ? toUTCString(startDate) : ''}`;
    } else {
      url += `&startDate=`;
    }

    if (endDate) {
      url += `&endDate=${endDate ? toUTCString(endDate) : ''}`;
    } else {
      url += `&endDate=`;
    }

    const axiosResponse = await axios.get<any>(url);
    return axiosResponse.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || 'An error occurred';
    return rejectWithValue(errorMessage);
  }
});
export const unAuthSearchActivities = createAsyncThunk<
  any,
  { keyword: string; startDate?: any; endDate?: any; page?: any },
  { rejectValue: string }
>('activity/unAutSearchHome', async ({ keyword, startDate, endDate, page }, { rejectWithValue }) => {
  try {
    const requestBody = {
      keyword,
      startDate: startDate || '',
      endDate: endDate || '',
      page: page || 0,
    };
    const axiosResponse = await axios.post<any>('api/unauthorized/home/search', requestBody);
    return axiosResponse.data;
  } catch (error) {
    throw error.response?.data?.message || 'An error occurred';
  }
});

function filtersAreEqual(filter1: any, filter2: any) {
  if (!filter1 || !filter2) return false;

  const relevantKeys = ['keyword', 'startDate', 'endDate'];
  return relevantKeys.every(key => filter1[key] === filter2[key]);
}

function resultsAreEqual(results1: any, results2: any) {
  return JSON.stringify(results1) === JSON.stringify(results2);
}

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    updateFilterProperty: (state, action: PayloadAction<{ property: keyof Filter; value: string | boolean | null | object }>) => {
      const { property, value } = action.payload;

      if (property === 'nearMe') {
        const nearMeValue = value as { latitude: string; longitude: string };
        state.RequestBody.filter.nearMe = nearMeValue;
      } else {
        (state.RequestBody.filter[property] as any) = value;
      }
    },
    updateEventType: (state, action: PayloadAction<string>) => {
      state.RequestBody.eventType = action.payload;
    },
    updateTabType: (state, action: PayloadAction<string>) => {
      state.tabType = action.payload;
    },
    updateFilterProperties: (state, action: PayloadAction<Partial<Filter>>) => {
      const updatedProperties = action.payload;
      state.RequestBody.filter = {
        ...state.RequestBody.filter,
        ...updatedProperties,
      };
    },
    updatePage: (state, action: PayloadAction<number>) => {
      state.RequestBody.page = action.payload;
    },
    updateSearchKeyword: (state, action: PayloadAction<string>) => {
      state.searchKeyWord = action.payload;
    },
    clearSearchKeyword: state => {
      state.searchKeyWord = '';
    },
    clearSearhedActivities: state => {
      state.searchKeyWord = '';
      state.searchResults = [];
    },
    clearExistingActivities: state => {
      state.activities = [];
    },
    updateVolunteeringEventId: (state, action: PayloadAction<number>) => {
      state.volunteeringEventId = action.payload;
    },
    updateFromDetailsPage: (state, action: PayloadAction<boolean>) => {
      state.isFromDetailsPage = action.payload;
    },
    updatePrevItemId: (state, action: PayloadAction<number>) => {
      state.prevItemId = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(filterActivities.pending, (state, action) => {
        const currentFilter = action.meta.arg.filter;
        const currentEventType = action.meta.arg.eventType;

        const currentFilterString = JSON.stringify(currentFilter);
        const previousFilterString = JSON.stringify(state.previousFilter);

        if (previousFilterString !== currentFilterString) {
          state.activities = [];
          state.previousFilter = currentFilter;
        }

        if (state.previousEventType !== currentEventType) {
          state.activities = [];
          state.previousEventType = currentEventType;
        }

        state.loading = true;
        state.error = 'No data';
      })
      .addCase(filterActivities.fulfilled, (state, action) => {
        const params = new URLSearchParams(window.location.search);
        const status = params.get('status');

        state.loading = false;

        const newUniqueActivities = action.payload.filter(
          newActivity => !state.activities.some(existingActivity => existingActivity.id === newActivity.id)
        );

        if (status === 'success' && action.meta.arg.page === 0 && action.meta.arg.filter.featured) {
          state.activities = action.payload;
        } else if (newUniqueActivities.length > 0) {
          state.activities = [...state.activities, ...newUniqueActivities];
        }
      })
      .addCase(filterActivities.rejected, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(filterActivitiesUnauthorized.pending, (state, action) => {
        const currentFilter = action.meta.arg.filter;
        const currentEventType = action.meta.arg.eventType;

        const currentFilterString = JSON.stringify(currentFilter);
        const previousFilterString = JSON.stringify(state.previousFilter);

        if (previousFilterString !== currentFilterString) {
          state.activities = [];
          state.previousFilter = currentFilter;
        }

        if (state.previousEventType !== currentEventType) {
          state.activities = [];
          state.previousEventType = currentEventType;
        }

        state.loading = true;
        state.error = 'No data';
      })
      .addCase(filterActivitiesUnauthorized.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;

        const newUniqueActivities = action.payload.filter(
          newActivity => !state.activities.some(existingActivity => existingActivity.id === newActivity.id)
        );

        if (newUniqueActivities.length > 0) {
          state.activities = [...state.activities, ...newUniqueActivities];
        }
      })
      .addCase(filterActivitiesUnauthorized.rejected, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(searchActivitiesCount.pending, state => {})
      .addCase(searchActivitiesCount.fulfilled, (state, action: PayloadAction<any>) => {
        state.searchCount = action.payload;
      })
      .addCase(searchActivitiesCount.rejected, (state, action: PayloadAction<string>) => {})
      .addCase(searchUnauthorizedActivitiesCount.pending, state => {})
      .addCase(searchUnauthorizedActivitiesCount.fulfilled, (state, action: PayloadAction<any>) => {
        state.searchCount = action.payload;
      })
      .addCase(searchUnauthorizedActivitiesCount.rejected, (state, action: PayloadAction<string>) => {})
      .addCase(searchActivities.pending, (state, action) => {
        const currentFilter = action.meta.arg;

        // Check if currentFilter is not null before proceeding
        if (currentFilter && !filtersAreEqual(currentFilter, state.lastSearchArg)) {
          state.searchResults = [];
          state.lastSearchArg = { ...currentFilter };
        }
        state.loading = true;
        state.error = null;
      })
      .addCase(searchActivities.fulfilled, (state, action) => {
        state.loading = false;
        // Check if action.meta.arg is not null before comparing
        if (action.meta.arg && filtersAreEqual(action.meta.arg, state.lastSearchArg)) {
          state.searchResults = [...state.searchResults, ...action.payload];
        } else {
          state.searchResults = action.payload;
          state.lastSearchArg = { ...action.meta.arg };
        }
      })

      .addCase(searchActivities.rejected, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(unAuthSearchActivities.pending, (state, action) => {
        const currentFilter = action.meta.arg;
        // Check if currentFilter is not null before proceeding
        if (currentFilter && !filtersAreEqual(currentFilter, state.lastSearchArg)) {
          state.searchResults = [];
          state.lastSearchArg = { ...currentFilter };
        }
        state.loading = true;
        state.error = null;
      })
      .addCase(unAuthSearchActivities.fulfilled, (state, action) => {
        state.loading = false;
        state.lastResult = state.searchResults;
        // Check if action.meta.arg is not null before comparing
        if (action.meta.arg && filtersAreEqual(action.meta.arg, state.lastSearchArg)) {
          if (!resultsAreEqual(state.searchResults, action.payload)) {
            state.searchResults = [...state.searchResults, ...action.payload];
          }
        } else {
          state.searchResults = action.payload;
          state.lastSearchArg = { ...action.meta.arg };
        }
      })
      .addCase(unAuthSearchActivities.rejected, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(searchBlogs.pending, state => {
        state.loading = true;
        state.error = null;
        state.searchResults = [];
      })
      .addCase(searchBlogs.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.searchResults = action.payload;
      })
      .addCase(searchBlogs.rejected, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchActivityDatas.pending, state => {
        state.mapResults = [];
      })
      .addCase(fetchActivityDatas.fulfilled, (state, action: PayloadAction<any>) => {
        state.mapResults = action.payload;
      })
      .addCase(fetchActivityDatas.rejected, (state, action: PayloadAction<string>) => {
        state.mapResults = [];
      })
      .addCase(fetchActivityUnauthorizedDatas.fulfilled, (state, action: PayloadAction<any>) => {
        // state.loading = false;
        state.mapResults = action.payload;
      })
      .addCase(signedPetition.fulfilled, (state, action) => {
        const updatedId = action.meta.arg?.number;
        const activityIndex = state.activities.findIndex(result => result.id === updatedId);
        const searchResultIndex = state.searchResults.findIndex(result => result.id === updatedId);

        if (activityIndex !== -1) {
          state.activities[activityIndex].participant = true;
          state.activities[activityIndex].petitionCountToday = state.activities[activityIndex].petitionCountToday + 1;
          state.activities[activityIndex].petitionCount = state.activities[activityIndex].petitionCount + 1;
        }
        if (searchResultIndex !== -1) {
          state.searchResults[searchResultIndex].participant = true;
          state.searchResults[searchResultIndex].petitionCountToday = state.searchResults[searchResultIndex].petitionCountToday + 1;
          state.searchResults[searchResultIndex].petitionCount = state.searchResults[searchResultIndex].petitionCount + 1;
        }
      })
      .addCase(leavePetition.fulfilled, (state, action) => {
        const updatedId = action.meta.arg?.number;
        const activityIndex = state.activities.findIndex(result => result.id === updatedId);
        const searchResultIndex = state.searchResults.findIndex(result => result.id === updatedId);

        if (activityIndex !== -1) {
          state.activities[activityIndex].participant = false;
          state.activities[activityIndex].petitionCountToday = state.activities[activityIndex].petitionCountToday - 1;
          state.activities[activityIndex].petitionCount = state.activities[activityIndex].petitionCount - 1;
        }
        if (searchResultIndex !== -1) {
          state.searchResults[searchResultIndex].participant = false;
          state.searchResults[searchResultIndex].petitionCountToday = state.searchResults[searchResultIndex].petitionCountToday - 1;
          state.searchResults[searchResultIndex].petitionCount = state.searchResults[searchResultIndex].petitionCount - 1;
        }
      })
      .addCase(reportPetition.fulfilled, (state, action) => {
        const updatedId = action.meta.arg?.number;
        const activityIndex = state.activities.findIndex(result => result.id === updatedId);
        const searchResultIndex = state.searchResults.findIndex(result => result.id === updatedId);

        if (activityIndex !== -1) {
          state.activities[activityIndex].reported = true;
        }
        if (searchResultIndex !== -1) {
          state.searchResults[searchResultIndex].reported = true;
        }
      })
      .addCase(deletePetitionById.fulfilled, (state, action) => {
        const updatedId = action.meta.arg.petitionId;

        state.activities = state.activities.filter(petitiion => petitiion.id !== updatedId);
        state.searchResults = state.searchResults.filter(petitiion => petitiion.id !== updatedId);
      })

      .addCase(deleteActivityByIds.fulfilled, (state, action) => {
        const updatedIds = action.meta.arg.activityIds;

        state.activities = state.activities.filter(activity => !updatedIds.includes(activity.id));
        state.searchResults = state.searchResults.filter(activity => !updatedIds.includes(activity.id));
      })
      .addCase(participateInActivityCard.fulfilled, (state, action) => {
        const argId = action.meta.arg.activityIds[0];
        const recurringParentId = state.volunteeringEventId;
        const remainingActivityCount = action.payload?.remainingActivityCount;
        const updatedId = localStorage.getItem('parentId')
          ? JSON.parse(localStorage?.getItem('parentId'))
          : recurringParentId
          ? recurringParentId
          : argId;

        const activityIndex = state.activities.findIndex(result => result.id === updatedId);
        const searchResultIndex = state.searchResults.findIndex(result => result.id === updatedId);

        if (activityIndex !== -1) {
          if (remainingActivityCount === 0) {
            state.activities[activityIndex].partialParticipant = true;
            state.activities[activityIndex].availableActivity = false;
            state.activities[activityIndex].participant = true;
            state.activities[activityIndex].permittedToFeatured = true;
            state.activities[activityIndex].showLeaveActivity = true;
            localStorage.removeItem('parentId');
          } else {
            state.activities[activityIndex].partialParticipant = true;
            state.activities[activityIndex].availableActivity = true;
            state.activities[activityIndex].participant = false;
            state.activities[activityIndex].permittedToFeatured = true;
            state.activities[activityIndex].showLeaveActivity = true;
            localStorage.removeItem('parentId');
          }
        }
        if (searchResultIndex !== -1) {
          if (remainingActivityCount === 0) {
            state.searchResults[searchResultIndex].participant = true;
            state.searchResults[searchResultIndex].availableActivity = false;
            state.searchResults[searchResultIndex].partialParticipant = true;
            state.searchResults[searchResultIndex].permittedToFeatured = true;
            state.activities[activityIndex].showLeaveActivity = true;
          } else {
            state.searchResults[searchResultIndex].showLeaveActivity = true;
          }
        }
      })
      .addCase(LeaveActivity.fulfilled, (state, action) => {
        const argId = action.meta.arg.activityIds[0];
        const recurringParentId = state.volunteeringEventId;

        const remainingActivityCount = action.payload?.afterLeaveActivityCount;
        const updatedId = recurringParentId ? recurringParentId : argId;

        const activityIndex = state.activities.findIndex(result => result.id === updatedId);
        const searchResultIndex = state.searchResults.findIndex(result => result.id === updatedId);

        if (activityIndex !== -1) {
          if (remainingActivityCount === 0) {
            state.activities[activityIndex].participant = false;
            state.activities[activityIndex].availableActivity = true;
            state.activities[activityIndex].partialParticipant = false;
            state.activities[activityIndex].permittedToFeatured = false;
            state.activities[activityIndex].showLeaveActivity = false;
          } else {
            state.activities[activityIndex].showLeaveActivity = true;
            state.activities[activityIndex].availableActivity = true;
            state.activities[activityIndex].participant = false;
          }
        }
        if (searchResultIndex !== -1) {
          if (remainingActivityCount === 0) {
            state.searchResults[searchResultIndex].participant = false;
            state.searchResults[searchResultIndex].availableActivity = true;
            state.searchResults[searchResultIndex].partialParticipant = false;
            state.searchResults[searchResultIndex].permittedToFeatured = false;
            state.searchResults[searchResultIndex].showLeaveActivity = false;
          } else {
            state.searchResults[searchResultIndex].showLeaveActivity = true;
            state.searchResults[searchResultIndex].availableActivity = true;
            state.searchResults[searchResultIndex].participant = false;
          }
        }
      })
      .addCase(ReportActivity.fulfilled, (state, action) => {
        const updatedId = action.meta.arg.activityId;
        const activityIndex = state.activities.findIndex(result => result.id === updatedId);
        const searchResultIndex = state.searchResults.findIndex(result => result.id === updatedId);

        if (activityIndex !== -1) {
          state.activities[activityIndex].reported = true;
        }
        if (searchResultIndex !== -1) {
          state.searchResults[searchResultIndex].reported = true;
        }
      })
      .addCase(rateActivity.fulfilled, (state, action) => {
        if (window.location.pathname === '/') {
          const updatedId = action.meta.arg.activityId;
          const activityIndex = state.activities.findIndex(result => result.id === updatedId);
          const searchResultIndex = state.searchResults.findIndex(result => result.id === updatedId);
          if (activityIndex != -1 && state.activities) {
            const avgRatingScore = (action.meta.arg.experienceRating + action.meta.arg.punctualityRating + action.meta.arg.teamRating) / 3;
            state.activities[activityIndex].rated = true;
            state.activities[activityIndex].starRating = parseFloat(avgRatingScore.toFixed(2));
          }
          if (searchResultIndex != -1) {
            const avgRatingScore = (action.meta.arg.experienceRating + action.meta.arg.punctualityRating + action.meta.arg.teamRating) / 3;
            state.searchResults[searchResultIndex].rated = true;
            state.searchResults[searchResultIndex].starRating = parseFloat(avgRatingScore.toFixed(2));
          }
        }
      })
      .addCase(fetchBookmarkedActivity.fulfilled, (state, action) => {
        const argId = action.meta.arg;
        const neededId = state.volunteeringEventId;

        const updatedId = neededId ? neededId : argId;

        const activityIndex = state.activities.findIndex(result => result.id === updatedId);
        const searchResultIndex = state.searchResults.findIndex(result => result.id === updatedId);
        if (activityIndex !== -1) {
          state.activities[activityIndex].bookMark = !state.activities[activityIndex].bookMark;
        }
        if (searchResultIndex !== -1) {
          state.searchResults[searchResultIndex].bookMark = !state.activities[activityIndex].bookMark;
        }
      })
      .addCase(fetchLikes.fulfilled, (state, action) => {
        const argId = action.meta.arg;
        const neededId = state.volunteeringEventId;

        const updatedId = neededId ? neededId : argId;

        const activityIndex = state.activities.findIndex(result => result.id === updatedId);
        const searchResultIndex = state.searchResults.findIndex(result => result.id === updatedId);

        const newLikes = extractNumberFromString(action.payload);
        if (activityIndex !== -1) {
          state.activities[activityIndex].liked = !state.activities[activityIndex].liked;
          state.activities[activityIndex].likeCount = newLikes;
        }
        if (searchResultIndex !== -1) {
          state.searchResults[searchResultIndex].liked = !state.activities[activityIndex].liked;
          state.activities[activityIndex].likeCount = newLikes;
        }
      })
      .addCase(signPetition.fulfilled, (state, action) => {
        const petitionId = action.meta.arg.petitionId;

        const activityIndex = state.activities.findIndex(result => result.id === petitionId);
        const searchResultIndex = state.searchResults.findIndex(result => result.id === petitionId);

        if (activityIndex !== -1) {
          state.activities[activityIndex].petitionCountToday = state.activities[activityIndex].petitionCountToday + 1;
        }
        if (searchResultIndex !== -1) {
          state.searchResults[searchResultIndex].petitionCountToday = state.searchResults[searchResultIndex].petitionCountToday + 1;
        }
      });
  },
});

export const {
  updateFilterProperty,
  updateEventType,
  updateFilterProperties,
  updateSearchKeyword,
  clearSearchKeyword,
  clearSearhedActivities,
  updateTabType,
  clearExistingActivities,
  updatePage,
  updateVolunteeringEventId,
  updateFromDetailsPage,
  updatePrevItemId,
} = filtersSlice.actions;

export default filtersSlice.reducer;
